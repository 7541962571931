import moment from "moment";

export default function orders(
  initialState = {
    orders: [],
    products: [],
    customers: [],
    coupons: [],
    tables: [],
    search: '',
    startDate: moment().startOf('month').toISOString(),
    endDate: moment().endOf('month').toISOString(),
    activeSort: '',
    sortOrder: '',
    totalCount: 0,
    pagination: {},
    page: 1
  },
  action
) {
  switch (action.type) {
    case 'SET_RESTAURANT_ADMIN_ORDERS':
      return {
        ...initialState,
        orders: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_ORDERS_PRODUCTS':
      return {
        ...initialState,
        products: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_ORDERS_CUSTOMERS':
      return {
        ...initialState,
        customers: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_ORDERS_COUPONS':
      return {
        ...initialState,
        coupons: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_ORDERS_TABLES':
      return {
        ...initialState,
        tables: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_ORDERS_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_ORDERS_START_DATE':
      return {
        ...initialState,
        startDate: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_ORDERS_END_DATE':
      return {
        ...initialState,
        endDate: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_ORDERS_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_ORDERS_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_ORDERS_TOTAL_COUNT':
      return {
        ...initialState,
        totalCount: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_ORDERS_PAGE':
      return {
        ...initialState,
        page: action.payload,
      };
    
    case 'SET_RESTAURANT_ADMIN_ORDERS_PAGINATION':
      return {
        ...initialState,
        pagination: action.payload,
      };


    case 'RESTAURANT_ADMIN_ORDERS_RESET':
      return {
        ...initialState,
        orders: [],
        products: [],
        customers: [],
        coupons: [],
        tables: [],
        search: '',
        startDate: moment().startOf('month').toISOString(),
        endDate: moment().endOf('month').toISOString(),
        activeSort: '',
        sortOrder: '',
        totalCount: 0,
        page: 1
      };

    default:
      return initialState;
  }
}
