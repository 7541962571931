import React, { useEffect, useState } from 'react'
import { Card, CardHeader, Media, Table, Row, Button, Badge, } from "reactstrap";
import { useSelector } from 'react-redux'
import { getEvent, deleteEvent, updateEventStatus } from "../../utils/middleware/events";
import { dispatch, dispatchNoPayload } from '../../../../utils/store'
import { Actions } from '../../../../redux/actions'
import { handleSortColumn, sortIcon } from '../../../../services/middleware';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoDataLabel from '../../../../components/NoDataLabel';
import CustomPagination from '../../../../components/Pagination';
import { RoutesActions } from '../../../../constants/General';
import EventModal from '../../components/EventModal';
import EventDashboard from "../../components/EventDashbaordModal";
import EventTicketModal from '../../components/EventTicketModal';
import SearchInput from '../../../../components/SearchInput';
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { faCopy, faEye, faPaperPlane, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";

export default function Events() {
  // Store
  
  const { events, search, startDate, endDate, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminEvents)  
  const { pageSize } = useSelector(state => state.user);

  const sampleEventStats = {
    totalTickets: 100,
    soldTickets: 75,
    revenue: 7500,
    attendedCount: 60,
    attendees: [
      {
        id: '1',
        name: 'John Doe',
        attendingTime: '2024-03-15 14:30',
        phoneNumber: '1234567890',
        email: 'john.doe@example.com',
        ticketsPurchased: 2,
        eventName: 'Summer Music Festival',
        eventDate: '2024-03-15 12:00',
        orderStatus: 'active',
        paymentAmount: 200
      },
      {
        id: '2',
        name: 'Jane Smith',
        attendingTime: '2024-03-15 14:45',
        phoneNumber: '9876543210',
        email: 'jane.smith@example.com',
        ticketsPurchased: 3,
        eventName: 'Summer Music Festival',
        eventDate: '2024-03-15 12:00',
        orderStatus: 'active',
        paymentAmount: 300
      },
      {
        id: '3',
        name: 'Alice Johnson',
        attendingTime: '2024-03-15 15:00',
        phoneNumber: '5555555555',
        email: 'alice.j@example.com',
        ticketsPurchased: 1,
        eventName: 'Summer Music Festival',
        eventDate: '2024-03-15 12:00',
        orderStatus: 'refunded',
        paymentAmount: 100
      }
    ]
  };

  // State
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDashbaordModal, setShowDashbaordModal] = useState(false);
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false); // State to control Ticket Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  // const [selectedEvent, setSelectedEvent] = useState({value: '', label: '', eventDate: ''});

  // Mount
  useEffect(() => {
    getEvent({ search, sortBy: activeSort, orderBy: sortOrder, page })
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.RestaurantAdminEvents.SetPage, pageCount)
    }
  }, [search, activeSort, sortOrder, totalCount, page, pageSize])

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedEvent(null)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedEvent(null);
  };

  const handleCloseDashbaordModal = () => {
    setShowDashbaordModal(false);
    setSelectedEvent(null)
  }

  const handleClosePublishModal = () => {
    setShowPublishModal(false);
    setSelectedEvent(null);
  };

  const copyEventUrl = (eventData) => {
    const restaurant = JSON.parse(localStorage.getItem("userData"));
    const name = restaurant.name;
    const url = `${window.location.origin}/event/${name}/${eventData.id}`
    navigator.clipboard.writeText(url)
      .then(() => toast.info("URL copied to clipboard!", {
        position: "bottom-right",
        theme: "colored",
      }))
      .catch(err => console.error("Failed to copy: ", err));
  };

  // Unmount
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminReservations.Reset), [])

  return (
    <div>
      <div className="custom-container">
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 space-between-div table-header-div">
                <h3 className="mb-0">Events</h3>
                <div className="right-div-wrap">
                  <Button color='primary' onClick={() => getEvent({ search, startDate, endDate, sortBy: activeSort, orderBy: sortOrder, page })}>
                    Refresh
                  </Button>
                  <SearchInput action={RoutesActions.reservations} />
                  <Button color='primary' onClick={() => {
                    setShowAddModal(true)
                  }}>
                    Add
                  </Button>
                </div>
              </CardHeader>
              {events.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope='col' className='serial-number cursor-pointer' onClick={() => handleSortColumn('', '', '', 'events')}>No.</th>
                    <th scope='col' className={`${activeSort === 'tableNumber' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('eventTitle', activeSort, sortOrder, 'events')}>Event Title <FontAwesomeIcon icon={sortIcon(activeSort, 'tableNumber', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'startTime' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('eventDate', activeSort, sortOrder, 'events')}>Date <FontAwesomeIcon icon={sortIcon(activeSort, 'startTime', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'endTime' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('evebtLocation', activeSort, sortOrder, 'events')}>Location <FontAwesomeIcon icon={sortIcon(activeSort, 'endTime', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'status' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('status', activeSort, sortOrder, 'events')}>Status <FontAwesomeIcon icon={sortIcon(activeSort, 'status', sortOrder)} /></th>
                    <th scope='col' className='text-right' >Option </th>
                  </tr>
                </thead>
                <tbody>
                  {events.map((event, index) => {
                    return <tr key={index}>
                      <th className='serial-number'>{index + 1 + (page - 1) * pageSize}</th>
                      <th>{event.eventTitle ? event.eventTitle : ''}</th>
                      <th>{event.eventDate ? event.eventDate : ''}</th>
                      <th>{event.eventLocation ? event.eventLocation : ''}</th>
                      <th>{event.eventstatus === 'publish' ? "Publish" : 'Draft'}</th>
                      <td className="text-right">
                        <div>
                          {event.eventstatus === 'publish' ? <Button color="info" className='action-icon-btn' title='Copy Event URL' onClick={() => {
                            copyEventUrl(event)
                          }} >
                            <FontAwesomeIcon icon={faCopy} />
                          </Button> : null}
                          <Button color="info" className='action-icon-btn' title='Publish' onClick={() => {
                            setSelectedEvent(event)
                            setShowPublishModal(true)
                          }} >
                            <FontAwesomeIcon icon={faPaperPlane} />
                          </Button>
                          <Button color="info" className='action-icon-btn' title='Dashbaord' onClick={() => {
                            setSelectedEvent(event)
                            setShowDashbaordModal(true)
                          }}>
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                          <Button color="success" className='action-icon-btn' title='Edit' onClick={() => {
                            setSelectedEvent(event)
                            setShowEditModal(true)
                          }}>
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                          <Button color="danger" className='action-icon-btn' title='Delete' onClick={() => {
                            setSelectedEvent(event);
                            setShowDeleteModal(true)
                          }}>
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  })}
                </tbody>
              </Table> : <NoDataLabel />}
              <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminEvents.SetPage, selected + 1)} />
            </Card>
          </div>
        </Row>
      </div>

      {showDeleteModal && <ConfirmationModal open={showDeleteModal} handleCloseModal={handleCloseDeleteModal} handleSuccess={() => { deleteEvent(selectedEvent.id, search, activeSort, sortOrder, page, handleCloseDeleteModal) }} />}
      {showPublishModal && <ConfirmationModal open={showPublishModal} handleCloseModal={handleClosePublishModal} handleSuccess={() => { updateEventStatus(selectedEvent.id, selectedEvent.eventstatus, search, activeSort, sortOrder, page, handleClosePublishModal) }} />}
      {showAddModal && <EventModal open={showAddModal} handleCloseModal={() => setShowAddModal(false)} setIsTicketModalOpen={setIsTicketModalOpen} setSelectedEvent={setSelectedEvent} activeSort sortOrder page search />}
      {showEditModal && <EventModal open={showEditModal} event={selectedEvent} handleCloseModal={handleCloseEditModal} search activeSort sortOrder page />}
      {showDashbaordModal && <EventDashboard open={showDashbaordModal} eventStats={sampleEventStats} event={selectedEvent} handleCloseModal={handleCloseDashbaordModal} search activeSort sortOrder page />}
      {isTicketModalOpen && <EventTicketModal open={isTicketModalOpen} event={selectedEvent} setIsTicketModalOpen={setIsTicketModalOpen} handleCloseModal={() => setIsTicketModalOpen(false)} activeSort sortOrder page search />}
    </div >
  )
}
