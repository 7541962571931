import { faChevronDown, faChevronUp, faSort } from '@fortawesome/free-solid-svg-icons';
import { SUPER_ADMIN_LOGIN_API, UPLOAD_IMAGE_API } from '../constants/Configs';
import { API_REQUEST, RoutesActions, USER_ROLE, serviceThrough } from '../constants/General';
import { Actions } from '../redux/actions';
import { Store } from '../redux/configureStore';
import { dispatch, dispatchNoPayload } from '../utils/store';
import API from './api';
import { handleError } from '../utils/toast';
import { setAccessToken, setLocalStorageUserData } from '../utils/session';

export const uploadImage = async (image, imageType, isObject = false) => {
  var payload = new FormData();

  if (isObject) {
    if (Array.isArray(image) && image?.length) {
      image.forEach((img, index) => {
        payload.append(`file${index + 1}`, img.fileUrl, img.name);
        payload.append(`fileName${index + 1}`, img.fileName || img.name);
      })
    } else {
      payload.append(`file1`, image.fileUrl, image.name);
      payload.append(`fileName1`, image.fileName || image.name);
    }
    payload.append('allowMultiple', true);
  } else {
    payload.append('file', image)
  }
  if (imageType) payload.append('fileCategory', imageType);

  return API(API_REQUEST.post, UPLOAD_IMAGE_API, payload).then(res => {
    return Boolean(payload.get("allowMultiple")) ? res?.data?.data || [] : {
      fileName: res?.data?.data?.fileName || '',
      fileLink: res?.data?.data?.fileLink || ''
    }
  })
}

export const generateDropdownOptionFromObject = (object) => {
  return Object.keys(object).map(key => {
    return {
      label: object[key],
      value: key
    }
  })
}

export const generateDropdownOptionForStates = (data) => {
  return data.map(state => {
    return {
      label: state,
      value: state
    }
  })
}

export const setSelectedDropdownForSameValue = (data) => {
  return {
    label: data,
    value: data
  }
}

export const setSelectedDropdownValue = (data, dataObject) => {
  return {
    label: dataObject[data],
    value: data
  }
}

export const setSelectedCountry = (country) => {
  let data = {
    label: (country === 'UK') ? 'UK' : 'India',
    value: (country === 'UK') ? 'UK' : 'IN'
  };

  return data
}

export const setSelectedService = (data) => {
  return data?.length ? data.map(datum => {
    return {
      label: serviceThrough[datum],
      value: datum
    }
  }) : []
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const sortData = (data, key, order = 'ASC', isBoolean = false) => {
  return key ? data.slice().sort((a, b) => {
    if (order === 'ASC') {
      return isBoolean ? b[key] - a[key] : typeof a[key] === 'number' ? a[key] - b[key] : a[key]?.localeCompare(b[key], undefined, { numeric: true, sensitivity: 'base' })
    } else {
      return isBoolean ? a[key] - b[key] : typeof a[key] === 'number' ? b[key] - a[key] : b[key]?.localeCompare(a[key], undefined, { numeric: true, sensitivity: 'base' })
    }
  }) : data;
};

export const handleSortColumn = (param, activeSort, sortOrder, route) => {
  let paramSortOrder = ''
  if (param) {
    if (activeSort !== param) {
      paramSortOrder = 'ASC';
    } else {
      paramSortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
    }
  }
  dispatch(Actions[RoutesActions[route]].SetActiveSort, param);
  dispatch(Actions[RoutesActions[route]].SetSortOder, paramSortOrder);
}

export const getPageSize = () => {
  return Store.getState()?.user?.pageSize;
}

export const trimObjectValues = (obj, excludedKey = '') => { // Add excludedKey parameter
  for (let key in obj) {
    if (key !== excludedKey) { // Skip excludedKey
      if (typeof obj[key] === 'string') {
        console.log(obj[key])
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        trimObjectValues(obj[key], excludedKey); // Recursively trim values of nested objects
      }
    }
  }
}

// Removes those keys which have empty values from object
export const removeEmptyKeysFromObject = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => Array.isArray(v) ? v?.length !== 0 : !!v));
}

// Check Allow to Access Feature
export const isAllowFeature = (module) => {
  const byPassArray = ['chatflow', 'chatwidget', 'sharedteaminbox', 'catalogs', 'chats', 'event', 'tickets', 'orders', 'finance', 'verify'];
  const newLinks = ['sharedteaminbox', 'catalogs', 'customnotification', 'customerengagement','wpchatbutton', 'clicktowpad','wpqrcode','wpchatlink']
  const restaurant = JSON.parse(localStorage.getItem('userData'));
  if (module === 'dashboard' || module === 'subscription' || newLinks.includes(module)) {
    return true
  }
  if (byPassArray.includes(module) &&  (restaurant.restaurantId === 44 || restaurant.restaurantId === 9)){
    return true
  }
  
  const primium = [35,55,52,50,46,48,45,9,91,45,49,53];

  if (module === 'chats' || module === 'chatflow' && primium.includes(restaurant.restaurantId)){
    return true
  }
  if (module === 'offers' || module === 'marketings' || module === 'campaigns' || module === 'templates' || module === 'templateAnalytics') {
    return Store.getState()?.restaurantAdmin?.restaurant?.subscription?.feature?.[module === 'offers' ? 'products' : 'campaigns'] || false
  }else {
    return Store.getState()?.restaurantAdmin?.restaurant?.subscription?.feature?.[module] || false
  }
}

// Redirect to Whatsapp
export const redirectToWhatsapp = (phone) => {
  if (phone) {
    window.open(encodeURI(`https://wa.me/${phone}`), "_self")
  }
}

// Generate file name
export const generateFileName = () => {
  var currentDate = new Date();

  var year = currentDate.getFullYear();
  var month = String(currentDate.getMonth() + 1).padStart(2, '0');
  var day = String(currentDate.getDate()).padStart(2, '0');
  var hours = String(currentDate.getHours()).padStart(2, '0');
  var minutes = String(currentDate.getMinutes()).padStart(2, '0');

  return `${year}${month}${day}_${hours}${minutes}`;
}

export const sortIcon = (activeSort, currentSort, sortOrder) => {
  return activeSort === currentSort ? sortOrder === "ASC" ? faChevronDown : faChevronUp : faSort
}

export const superAdminLogin = (restaurantId, isFromAdminToRestaurant, navigate) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  dispatch(Actions.SuperAdmin.SetLoading, true)
  API(API_REQUEST.post, SUPER_ADMIN_LOGIN_API, { restaurantId, isFromAdminToRestaurant })
    .then((res) => {
      setLocalStorageUserData(res?.data?.data?.userData);
      setAccessToken(res?.data?.data?.tokenData);
      dispatch(Actions.User.SetIsAdminUser, USER_ROLE.SUPER_ADMIN === res?.data?.data?.userData?.role)
      dispatchNoPayload(Actions.RestaurantAdmin.Reset)
      navigate('/admin')
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}