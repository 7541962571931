import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, Form, Button, Container, Spinner } from "reactstrap";
import { BrandName, paymentFailedMessage, paymentSuccessMessage } from '../../constants/General';
import API from "../../services/api";
import { API_REQUEST } from "../../constants/General";
import { EVENT_API, EVENT_PAYMENT_VERIFY_API } from "../../constants/Configs";
import { Buffer } from "buffer"; // Import buffer for better compatibility


export default function PaymentStatus() {
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const success = !!Number(pathName.includes('success'));
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        // Get a specific parameter
        const sessionId = urlParams.get('session_id');
        const params = {
          sessionId
        }
        const response = await API(API_REQUEST.post, `${EVENT_PAYMENT_VERIFY_API}`, params);
        setOrder(response.data.data); // Assuming the API response has a `data` field
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    if (success) {
      fetchEventData();
    }
  }, []);

  function textToBase64(text) {
    return Buffer.from(text, "utf-8").toString("base64");
  }

  const handleViewTicket = () => {
    if(order){
      console.log('order', order?.order?.id);
      order && navigate(`/ticket/BonzaiLondon/${textToBase64(order?.order?.id.toString())}`);
    }
  }

  // State
  const [countdown, setCountdown] = useState(1800);

  if (loading) {
    return (
      <Container className="d-flex justify-content-center my-4">
        <Spinner color="primary" />
      </Container>
    );
  }


  return (
    <div className="pre-login-section">
      <div className="pre-login-wrap">
        <div className="logo-wrap">
          <span>{BrandName}</span>
        </div>
        <Card className="shadow">
          <CardBody>
            <Form role="form">
              <div className="text-center mb-4">
                {success ? <FontAwesomeIcon color={"#28a745"} icon={faCircleCheck} className='email-verification-check-icon' /> :
                  <FontAwesomeIcon color={"#dc3545"} icon={faCircleXmark} className='email-verification-check-icon' />}
              </div>
              <div className="text-center mb-4">
                <h1>{success ? 'Success!' : 'Failed!'}</h1>
              </div>
              <div className='text-center center-div'>{success ? paymentSuccessMessage : paymentFailedMessage}</div>
              <div className="text-center mt-4">
                <Button color="primary" className="m-2" onClick={handleViewTicket}>View Ticket</Button>
                <Button color="secondary" className="m-2" onClick={() => navigate('/organization')}>Organization</Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
