import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { QRCodeCanvas } from "qrcode.react";

const TicketDetails = ({ value, index, order, EventDetails, organization }) => {
  return (
    <Card className="p-3 mb-3" style={{ maxWidth: '100%', border: '2px solid black' }}>
      <CardBody>
        <Row>
          <Col md={4} className="text-center bg-danger text-white p-3">
            <div>{value.eventName}</div>
            <div style={{ fontSize: '50px', fontWeight: 'bold' }}>{++index}</div>
            <div style={{display: "flex", justifyContent: "center"}}> 
              <QRCodeCanvas value={value.ticketUniqueId} />
            </div>
          </Col>
          <Col md={8} className="p-3">
            {/* <h3 className="fw-bold">You're Invited</h3> */}
            <h2 className="fw-bold">{value?.ticketName|| "General Zone"}</h2>
            <hr />
            <Row>
              <Col><strong>Quntity:</strong> {value.ticketQuantity}</Col>
            </Row>
            <Row>
              <Col><strong>Date:</strong> {EventDetails(order?.event)}</Col>
            </Row>
            <Row>
              <Col><strong>Issued By:</strong> {organization || "N|A"}</Col>
            </Row>
            <Row>
              <Col><strong>Location:</strong> {order?.event.eventLocation}</Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default TicketDetails;
