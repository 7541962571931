export function eventsListDTO(data) {
  let events = [];

  if (data?.length) {
    events = data.map((event) => ({
      id: event?.id || '',
      eventMedia: event?.eventMedia || '',
      eventMedias: event?.eventMedias || '',
      mediaType: event?.mediaType || '',
      eventTitle: event?.eventTitle || '',
      eventSummery: event?.eventSummery || '',
      eventName: event?.eventName || '',
      eventType: event?.eventType || '',
      eventDate: event?.eventDate || '',
      eventEndDate: event?.eventEndDate || '',
      eventStartTime: event?.eventStartTime || '',
      eventEndTime: event?.eventEndTime || '',
      isOnlineEvent: event?.isOnlineEvent || '',
      eventLocation: event?.eventLocation || '',
      eventstatus: event?.eventstatus || '',
      isReservation: event?.isReservation || '',
      eventOverview: event?.eventOverview || '',
      eventEntryTime: event?.eventEntryTime || '',
      isAgeRestircted: event?.isAgeRestircted || '',
      isParentNeed: event?.isParentNeed || '',
      isHours: event?.isHours || '',
      allowAges: event?.allowAges || '',
      isParkingAvailable: event?.isParkingAvailable || '',
      isPaidParking: event?.isPaidParking || '',
      parkingCharges: event?.parkingCharges || '',
      isPaidEvent: event?.isPaidEvent || '',
      isdonationEvent: event?.isdonationEvent || '',
      ticketName: event?.ticketName || '',
      ticketQuantity: event?.ticketQuantity || '',
      ticketPrice: event?.ticketPrice || '',
      salesStartDateTime: event?.salesStartDateTime || '',
      salesEndDateTime: event?.salesEndDateTime || '',
      restaurants: event?.salesEndDateTime || '',
    }))
  }

  return events;
}

export function eventsSelectListDTO(data) {
  let events = [];

  if (data?.length) {
    events = data.map((event) => ({
      value: event?.id || '',
      label: event?.eventTitle || '',
      eventDate: event?.eventDate || '',
    }))
  }

  return events;
}