import React, { useState } from 'react'; 
import { Modal, ModalBody, ModalHeader, Form, Row, Col, Button, Input } from 'reactstrap';

const NewFlowModal = ({ isOpen, onClose, onSubmit }) => {
  const [flowName, setFlowName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (flowName) {
      await onSubmit(flowName);
      setFlowName(''); // Clear input field after submission
    }
  };

  if (!isOpen) return null;

  return (
    <Modal className="modal-dialog-centered" style={{width : '30% !important' }} isOpen={isOpen} toggle={onClose} fullscreen="sm">
      <ModalHeader toggle={onClose}>
        Enter Flow Name
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Row style={{gap:10}}>
            <Col md={12}>
              <Input
                onChange={(e) => setFlowName(e.target.value)}
                placeholder="Flow Name"
                fieldName="Flow Name"
              />
            </Col>
          </Row>
          <Button className="btn btn-primary" style={{marginTop:'20px'}} type="submit">Create Flow</Button>
          <Button style={{marginTop:'20px'}} type="button" onClick={onClose}>Cancel</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default NewFlowModal;
