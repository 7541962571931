import React, { useEffect, useState } from 'react'
import { Card, CardHeader, Table, Row, Button} from "reactstrap";
import { useSelector } from 'react-redux'
import { deleteTicket, getEvent, getEventTickets } from '../../utils/middleware/events'
import { dispatch, dispatchNoPayload } from '../../../../utils/store'
import { Actions } from '../../../../redux/actions'
import { handleSortColumn, sortIcon } from '../../../../services/middleware';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoDataLabel from '../../../../components/NoDataLabel';
import CustomPagination from '../../../../components/Pagination';
import EventTicketModal from '../../components/EventTicketModal';
import Select from 'react-select';
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { faCopy, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';
import { toast } from "react-toastify";

export default function Events() {
  // Store
  const { tickets, search, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminTickets)
  const { eventList } = useSelector(state => state.restaurantAdminEvents)
  // const tickets = useSelector(state => state.RestaurantAdminTickets)
  const { pageSize } = useSelector(state => state.user)

  // State
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Mount
  useEffect(() => {
    getEvent({ search, sortBy: activeSort, orderBy: sortOrder, page })
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.RestaurantAdminReservations.SetPage, pageCount)
    }
  }, [search, activeSort, sortOrder, totalCount, page, pageSize])

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedTicket(null)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedTicket(null);
  };

  const copyEventUrl = (id) => {
    const restaurant = JSON.parse(localStorage.getItem("userData"));
    const name = restaurant.name;
    const url = `${window.location.origin}/event/${name}/${id}`
    navigator.clipboard.writeText(url)
    .then(() => toast.info("URL copied to clipboard!", {
            position: "bottom-right",
            theme: "colored",
          }))
    .catch(err => console.error("Failed to copy: ", err));
  };

  const handleChange = (selected) => {
    if (selected !== null) {
      getEventTickets({ id: selected.value });
    }
    setSelectedOption(selected);
  };

  const getFormatedDate = (date) => {
    const systemTimezone = moment.tz.guess();

    // Convert UTC to local system time
    const localDate = moment.tz(date, systemTimezone).format("DD-MM-YYYY HH:mm:ss");

    // Format as per local system preferences
    const systemFormattedDate = new Intl.DateTimeFormat(undefined, {
      dateStyle: "full",
      timeStyle: "long",
      timeZone: systemTimezone
    }).format(new Date(date));

    return localDate;
  };

  // Unmount
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminReservations.Reset), [])
  return (
    <div>
      <div className="custom-container">
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 space-between-div table-header-div">
                <h3 className="mb-0">Event Tickets</h3>
                <div className="right-div-wrap">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select an Event..."
                    value={selectedOption}
                    onChange={handleChange}
                    isClearable={true}
                    isSearchable={true}
                    name="eventName"
                    options={eventList}
                  />
                  <Button color='primary' onClick={() => {
                    setShowAddModal(true)
                  }}>
                    Add
                  </Button>
                </div>
              </CardHeader>
              {tickets.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope='col' className='serial-number cursor-pointer' onClick={() => handleSortColumn('', '', '', 'events')}>No.</th>
                    <th scope='col' className={`${activeSort === 'tableNumber' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('eventTitle', activeSort, sortOrder, 'events')}>Event Title <FontAwesomeIcon icon={sortIcon(activeSort, 'tableNumber', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'startTime' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('eventDate', activeSort, sortOrder, 'events')}>Price <FontAwesomeIcon icon={sortIcon(activeSort, 'startTime', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'startTime' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('eventDate', activeSort, sortOrder, 'events')}>Sold <FontAwesomeIcon icon={sortIcon(activeSort, 'startTime', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'endTime' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('evebtLocation', activeSort, sortOrder, 'events')}>Gross <FontAwesomeIcon icon={sortIcon(activeSort, 'endTime', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'status' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('status', activeSort, sortOrder, 'events')}>Status <FontAwesomeIcon icon={sortIcon(activeSort, 'status', sortOrder)} /></th>
                    <th scope='col' className='text-right' > Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {console.log(tickets)}
                  {tickets.map((ticketData, index) => {
                    return <tr key={index}>
                      <th className='serial-number'>{index + 1 + (page - 1) * pageSize}</th>
                      <th>{ticketData.event.eventTitle ? ticketData.event.eventTitle : ''}</th>
                      <th>{ticketData.ticketPrice ? `£${parseFloat(ticketData.ticketPrice)}` : '£00.00'}</th>
                      <th>{ticketData.ticketSolds || 0}/{ticketData.ticketQuantity}</th>
                      <th>£{parseFloat(parseInt(ticketData.ticketSolds) * parseFloat(ticketData.ticketPrice))|| 0.00}</th>
                      <th>{ticketData.event.eventstatus && ticketData.event.eventstatus.toLowerCase() === 'publish' ? "Sale Live" : 'Draft'}</th>
                      <td className="text-right">
                        <div>
                          {ticketData.event.eventstatus && ticketData.event.eventstatus.toLowerCase() === 'publish' ?
                            <Button color="info" className='action-icon-btn' title='Copy Event url' onClick={() => copyEventUrl(ticketData.eventId)}>
                              <FontAwesomeIcon icon={faCopy} />
                            </Button> : ""}
                          <Button color="success" className='action-icon-btn' title='Edit' onClick={() => {
                            setSelectedTicket(ticketData)
                            setShowEditModal(true)
                          }}>
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                          <Button color="danger" className='action-icon-btn' title='Delete' onClick={() => {
                            setSelectedTicket(ticketData)
                            setShowDeleteModal(true)
                          }}>
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  })}
                </tbody>
              </Table> : <NoDataLabel />}
              <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminReservations.SetPage, selected + 1)} />
            </Card>
          </div>
        </Row>
      </div>
      {showDeleteModal && <ConfirmationModal open={showDeleteModal} handleCloseModal={handleCloseDeleteModal} handleSuccess={() => { deleteTicket(selectedTicket.id, selectedOption, handleCloseDeleteModal) } } />}
      {showAddModal && <EventTicketModal open={showAddModal} handleCloseModal={() => setShowAddModal(false)} activeSort sortOrder page search />}
      {showEditModal && <EventTicketModal open={showEditModal} ticketsData={selectedTicket} handleCloseModal={handleCloseEditModal} search activeSort sortOrder page />}
    </div >
  )
}
