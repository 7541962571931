import React, { useState } from "react";
import { Container, Row, Col, Form, FormGroup, Input, Button, Label, Card, CardBody } from "reactstrap";
import ReactQuill from "react-quill";

const OverviewComponent = ({eventOverView, setEventOverView}) => {
  const [videoLink, setVideoLink] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);

  const handleVideoLinkChange = (e) => {
    const link = e.target.value;
    setVideoLink(link);
    setIsInvalid(!isValidVideoLink(link));
  };

  const isValidVideoLink = (link) => {
    return link.match(/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be|vimeo\.com)\/.*$/);
  };

  return (
    <div className="p-4 border rounded">
      <h5>Overview</h5>
      <p>Add more details about your event and include what people can expect if they attend.</p>
      <FormGroup>
        <Label for="text1">Description</Label>
        <ReactQuill
          value={eventOverView}
          onChange={(content) => setEventOverView(content)}
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link', 'image'],
              ['clean'],
            ],
          }}
          style={{ height: "300px", width: "100%", marginBottom: "50px" }}
          theme="snow"
        />
      </FormGroup>

      {/* <FormGroup>
        <Label>Image Upload</Label>
        <Card className="text-center p-4 border">
          <CardBody>
            <p>Drag & drop or click to add image.</p>
            <p className="text-muted">JPEG, PNG, GIF, no larger than 10MB.</p>
          </CardBody>
        </Card>
      </FormGroup>

      <FormGroup>
        <Label for="video">Video link*</Label>
        <Input
          type="text"
          id="video"
          placeholder="Enter video link"
          value={videoLink}
          onChange={handleVideoLinkChange}
          invalid={isInvalid}
        />
        {isInvalid && <p className="text-danger">Invalid Video link</p>}
      </FormGroup>

      <FormGroup>
        <Label for="text2">Additional Information</Label>
        <Input type="textarea" id="text2" placeholder="Enter details..." />
      </FormGroup>

      <Row className="mt-3">
        <Col>
          <Button color="primary" block>Add text</Button>
        </Col>
        <Col>
          <Button color="secondary" block>Add image</Button>
        </Col>
        <Col>
          <Button color="info" block>Add video</Button>
        </Col> */}
      {/* </Row> */}
    </div>
  );
};

export default OverviewComponent;
