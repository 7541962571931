import React, { useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import API from "../../services/api";
import { API_REQUEST } from "../../constants/General";
import { EVENT_PAYMENT_API } from "../../constants/Configs";

const TicketModal = ({ isOpen, toggle, ticket, event, increaseQuantity, decreaseQuantity, getTotalPrice, setSelectedTickets }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    
    console.log(event)

    function validateOrderData(orderData) {
        // Helper function to check if a value is a non-empty string
        function isValidString(value) {
            return typeof value === "string" && value.trim() !== "";
        }

        // Helper function to check if mobile number is exactly 10 digits
        function isValidMobile(number) {
            return /^[0-9]{10}$/.test(number);
        }

        // Error messages for invalid fields
        if (!isValidString(orderData.firstName)) {
            return { success: false, message: "First name cannot be empty." };
        }
        if (!isValidString(orderData.lastName)) {
            return { success: false, message: "Last name cannot be empty." };
        }
        if (!isValidString(orderData.email)) {
            return { success: false, message: "Email cannot be empty." };
        }
        if (!isValidMobile(orderData.phone)) {
            return { success: false, message: "Mobile number must be exactly 10 digits." };
        }

        return { success: true, message: "Validation passed." };
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare data for Stripe payment
        const paymentData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: mobileNo,
            eventId: event.id,
            ticketQuantity: Object.entries(ticket)
                .filter(([key]) => key !== "total") // Exclude "total" key
                .map(([ticketId, ticketQuantity]) => ({
                    ticketId: Number(ticketId),
                    ticketQuantity
                })),
            addonQuantity: [],
            currency: "gbp",
            orderStatus: "pending",
            orderDateTime: new Date().toISOString(),
            orderPaymentType: "Online",
            grossSales: ticket.total,
            netSales: ticket.total,
            netRevenue: ticket.total,
            restaurantId: event.restaurantId
        };
        console.log(paymentData);
        try {
            const { success, message} = await validateOrderData(paymentData);
            if(success){
                // Redirect to Stripe payment gateway
                const response = await API(API_REQUEST.post, EVENT_PAYMENT_API, paymentData);
                console.log(response.data.data.url);
                if(response.data.data.url !== undefined){
                    window.location.href = response.data.data.url; // Redirect to Stripe payment page
                }
                // window.location.href = response.data.url; // Redirect to Stripe payment page
            } else {
                setErrorMessage(message);
            }
            
        } catch (error) {
            console.error('Error creating payment intent:', error);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Purchase Ticket: {ticket.ticketName}</ModalHeader>
            <ModalBody>
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
                <FormGroup>
                    <Label for="firstName">First Name</Label>
                    <Input
                        type="text"
                        id="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="lastName">Last Name</Label>
                    <Input
                        type="text"
                        id="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="mobileNo">Mobile Number</Label>
                    <Input
                        type="tel"
                        id="mobileNo"
                        value={mobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="quantity">Total Quantity</Label>
                    {event.tickets.map((ticketdata) => (
                        <div key={ticket.id} className="bg-white p-4 rounded-lg shadow">
                            <p className="text-lg font-bold">{ticketdata.ticketName}</p>
                            <div className="mt-4 flex items-center justify-between">
                                <p className="text-gray-600">£{ticketdata.ticketPrice}</p>
                                <div className="flex items-center space-x-2">
                                    <button
                                        className="px-3 py-1 bg-gray-200 rounded-lg"
                                        onClick={() => decreaseQuantity(ticketdata.id)}
                                    >
                                        -
                                    </button>
                                    <p>{ticket[ticketdata.id] || 0}</p>
                                    <button
                                        className="px-3 py-1 bg-gray-200 rounded-lg"
                                        onClick={() => increaseQuantity(ticketdata.id)}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </FormGroup>
                <FormGroup>
                    <Label for="totalPrice">Total Price</Label>
                    <Input
                        type="text"
                        id="totalPrice"
                        value={`£${ticket.total}`}
                        readOnly
                    />
                </FormGroup>
                <Button color="primary" type="submit" onClick={handleSubmit}>
                    Buy Now
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalBody>
        </Modal>
    );
};

export default TicketModal;