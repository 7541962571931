import React, { useState, useEffect, useRef } from "react";
import { Input, Button, InputGroup } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { dispatch } from "../../../../../utils/store";
import { ImageType } from "../../../../../constants/General";
import { Actions } from "../../../../../redux/actions";
import { uploadNodeImage } from "../../../utils/middleware/chatAtomation";
import { toast } from "react-toastify";
import InputField from './../../../../../components/InputField';


export default function Sidebar({
  nodeText,
  setNodeText,
  nodeType,
  setNodeType,
  nodeValue,
  setNodeLabel,
  nodeLabel,
  buttonUrl,
  setButtonUrl,
  setNodeValue,
  selectedNode,
  setSelectedElements,
  setSelectedFile,
  setFileUrl,
  isDisable,
  selectedFile,
  selectedPDFFile,
  selectedVideoFile,
  setSelectedVideoFile,
  setSelectedPDFFile,
  nodes,
}) {
  const [conditionValues, setConditionValues] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isNodeDisabled, setIsNodeDisabled] = useState(false);
  const [isEndNodeDisabled, setIsEndNodeDisabled] = useState(false);
  const [nodeID, setNodeID] = useState("");
  const [uploadPDF, setUploadPDF] = useState([{ id: 0, pdf: null }]);
  const [uploadImages, setUploadImages] = useState([{ id: 0, file: null }]);
  const [uploadVideo, setUploadVideo] = useState([{ id: 0, video: null }]);
  const [url, setUrl] = useState({ title: "", message: "", url: "" });
  const pdfInputRefs = useRef(uploadPDF.map(() => React.createRef()));
  const imageInputRefs = useRef(uploadImages.map(() => React.createRef()));
  const videoInputRefs = useRef(uploadVideo.map(() => React.createRef()));



  // useEffect(() => {
  //   if (selectedNode && selectedNode.type === "conditionnode") {
  //     setConditionValues(nodeValue);
  //   }
  // }, [selectedNode, nodeValue]);
  useEffect(() => {
    const startNodes = nodes.filter((node) => node.type === "startnode");
    const endNodes = nodes.filter((node) => node.type === "endnode");
    if (startNodes.length > 0) {
      setIsNodeDisabled(true);
    } else {
      setIsNodeDisabled(false);
    }
    if (endNodes.length > 0) {
      setIsEndNodeDisabled(true);
    } else {
      setIsEndNodeDisabled(false);
    }
  }, [nodes]);

  const handleDragStart = (event) => {
    if (isNodeDisabled) {
      event.preventDefault(); // Prevent dragging
    } else {
      onDragStart(event, "startnode"); // Allow dragging
    }
  };

  useEffect(() => {
    setUploadPDF([{ id: 0, pdf: null }]);
    setUploadImages([{ id: 0, file: null }]);
    setUploadVideo([{ id: 0, video: null }]);
    pdfInputRefs.current.forEach((ref) => {
      if (ref.current) {
        ref.current.value = "";
      }
    });
    imageInputRefs.current.forEach((ref) => {
      if (ref.current) {
        ref.current.value = "";
      }
    });
    videoInputRefs.current.forEach((ref) => {
      if (ref.current) {
        ref.current.value = "";
      }
    });
  }, [selectedNode]);


  useEffect(() => {
    if (
      selectedNode &&
      (selectedNode.type === "conditionnode" ||
        selectedNode.type === "textnode")
    ) {
      if (selectedNode.type === "textnode" && nodeID !== selectedNode.id) {
        setIsButtonDisabled(false);
      }
      setConditionValues(nodeValue);
      setNodeID(selectedNode.id);
    } else {
      setConditionValues([]);
    }
  }, [selectedNode, nodeValue]);

  const handleLabelInputChange = (event) => {
    const value = nodeText;
    if(event.target.value.length > 1024){
      toast.info('Message value exceeds 1024 characters.', {
        position: "bottom-right",
        theme: "colored",
      });
      setNodeText(value);
    }else{
      setNodeText(event.target.value);
    }
  };


  const handleFileUrl = (event) => {
    setFileUrl(event.target.value);
    if (event.target.value.length > 0) {
      setSelectedFile(null);
    }
  };

  const handleTypeInputChange = (event) => {
    setNodeType(event.target.value);
  };


  const handleInputChange = (value) => {
    setNodeLabel(value);
  };

  // const handleAddValueInputChange = (value, id) => {
  //   const updatedValues = conditionValues.map((item) =>
  //     item.id === id ? { ...item, value } : item
  //   );

  //   if (selectedNode.type === "textnode" && updatedValues.length === 3) {
  //     setIsButtonDisabled(true);
  //   } else {
  //     setIsButtonDisabled(false);
  //   }
  //   setConditionValues(updatedValues);
  //   setNodeValue(updatedValues);
  // };

  const handleAddValueInputChange = (value, id) => {
    let message = "";
    console.log(value,id)
    const updatedValues = conditionValues.map((item) => {
      if (item.id === id) {
        if (value.length > 20) {
          message = "Button value exceeds 20 characters.";
          return { ...item, value: item.value }; // Retain old value
        }
        return { ...item, value }; // Update with new value
      }
      return item; // Return unchanged items
    });
  
    if (message) {
      toast.info(message, {
        position: "bottom-right",
        theme: "colored",
      }); // Show an alert if the value exceeds 20 characters
    }
  
    if (selectedNode.type === "textnode" && updatedValues.length === 3) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
    setConditionValues(updatedValues);
    setNodeValue(updatedValues);
  };
  
  
  const addConditionValue = () => {
    let temp = conditionValues;
  
    if(selectedNode.type === "textnode" && temp.length === 3){
      toast.info("Max. 3 buttons are allowed.", {
        position: "bottom-right",
        theme: "colored",
      });
      setIsButtonDisabled(true);
    }else{
      const newInput = { id: uuidv4(), value: "" };
      let updatedValues = [];
      if (temp.length > 0) {
        updatedValues = [...temp, newInput];
      } else {
        updatedValues.push(newInput);
      }
      setConditionValues(updatedValues);
      setNodeValue(updatedValues);
    }

  };

  const removeConditionValue = (id) => {
    const updatedValues = conditionValues.filter((item) => item.id !== id);
    if (selectedNode.type === "textnode" && updatedValues.length === 3) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
    setConditionValues(updatedValues);
    setNodeValue(updatedValues);
  };

  const onDragStart = (event, nodeType) => {
    if (isDisable) {
      event.preventDefault();
    } else {
      event.dataTransfer.setData("application/reactflow", nodeType);
      event.dataTransfer.effectAllowed = "move";
    }
  };

  const callUploadFiles = async (fileData, fileType) => {
    // dispatch(Actions.RestaurantAdmin.SetLoading, true)        
    // Process each data item in the uploadData array             
    // const fileType = fileData[0]?.type?.split('/')[1];        
    if (fileData.length > 0) {
      await uploadNodeImage(
        fileData,
        fileType === "pdf"
          ? ImageType.CAMPAIGN_PDF
          : fileType === "video"
            ? ImageType.CAMPAIGN_VIDEO
            : ImageType.CAMPAIGN_IMAGE,
        true
      ).then((res) => {
        if (fileType === "pdf") {
          setSelectedPDFFile(res.data.data);
        } else if (fileType === "video") {
          setSelectedVideoFile(res.data.data);
        } else {
          setSelectedFile(res.data.data);
        }
      });
    }
  };

  const handlePDFFileChangeNew = (index) => (event) => {
    const newFiles = [...uploadPDF];
    newFiles[index].pdf = event.target.files[0]; // Store the selected file
    setUploadPDF(newFiles);
  };

  // Add a new file input
  const handleAddPDFFileInput = () => {
    if (uploadPDF.length < 3) {
      setUploadPDF([...uploadPDF, { id: uploadPDF.length, pdf: null }]);
    }
  };

  // Remove a specific file input
  const handleRemovePDFFileInput = (index) => () => {
    setUploadPDF(uploadPDF.filter((_, i) => i !== index));
  };

  // Handle PDF upload (optional implementation)
  const handleUploadPDFNew = () => {
    // Logic for uploading the PDFs
    const pdfArray = uploadPDF.map((item) => item.pdf);

    if (uploadPDF[0].pdf) {
      callUploadFiles(pdfArray, "pdf");
    } else {
      toast.error("Please Choose the PDF", {
        position: "bottom-right",
        theme: "colored",
      });
    }
    // Example: you could send this `uploadPDF` array to a backend
  };


  const handleFileChangeNew = (index) => (event) => {
    const newImages = [...uploadImages];
    newImages[index].file = event.target.files[0]; // Store the selected image
    setUploadImages(newImages);
  };

  // Add a new image input, but only if there are fewer than 3 inputs
  const handleAddFileInput = () => {
    if (uploadImages.length < 3) {
      const newId = uploadImages.length; // Generate a new id
      setUploadImages([...uploadImages, { id: newId, file: null }]);
    }
  };

  // Remove a specific image input
  const handleRemoveFileInput = (index) => () => {
    setUploadImages(uploadImages.filter((_, i) => i !== index));
  };

  // Handle image upload (optional implementation)
  const handleUploadImageNew = () => {
    // Logic for uploading the images
    const imageArray = uploadImages.map((item) => item.file);
    if (uploadImages[0].file) {
      callUploadFiles(imageArray, "image");
    } else {
      toast.error("Please Choose the Image", {
        position: "bottom-right",
        theme: "colored",
      });
    }
    // You can send the `uploadImages` array to a backend
  };

  const handleVideoChangeNew = (index) => (event) => {
    const newVideo = [...uploadVideo];
    newVideo[index].file = event.target.files[0]; // Store the selected image
    setUploadVideo(newVideo);
  };

  const handleTitleChange = (event) => {
    if(event.target.value.length > 20){
      return
    }
    setButtonUrl((prev) => ({
      ...prev,
      title: event.target.value,
    }));
  };

  const handleUrlChange = (event) => {
    if(event.target.value.length > 2000){
      return
    }
    setButtonUrl((prev) => ({
      ...prev,
      url: event.target.value,
    }));
  };

  const handleMessageChange = (event) => {
    if(event.target.value.length > 1024){
      return
    }
    setButtonUrl((prev) => ({
      ...prev,
      message: event.target.value,
    }));
  };

  // Add a new image input, but only if there are fewer than 3 inputs
  const handleAddVideoInput = () => {
    if (uploadVideo.length < 3) {
      const newId = uploadVideo.length; // Generate a new id
      setUploadVideo([...uploadVideo, { id: newId, file: null }]);
    }
  };

  // Remove a specific image input
  const handleRemoveVideoInput = (index) => () => {
    setUploadVideo(uploadVideo.filter((_, i) => i !== index));
  };

  // Handle image upload (optional implementation)
  const handleUploadVideoNew = () => {
    // Logic for uploading the images
    const videoArray = uploadVideo.map((item) => item.file);

    if (uploadVideo[0].video) {
      callUploadFiles(videoArray, "video");
    } else {
      toast.error("Please Choose the Video", {
        position: "bottom-right",
        theme: "colored",
      });
    }
    // You can send the `uploadImages` array to a backend
  };


  return (
    <aside className="sidebar-container">
      {selectedNode ? (
        <div>
          <h3 className="update-label">Update Value</h3>
          {selectedNode.type === "conditionnode" ||
            selectedNode.type === "textnode" ? (
            <>
              {selectedNode.type === "textnode" && (
                <>
                  <label className="node-label">Add message:</label>
                  <span style={{textAlign: "right", marginLeft: "79%",  fontSize: "11px", color: 'red'}} >{nodeText.length}/1024</span>
                  <Input
                    type="textarea"
                    className="custom-input"
                    placeholder="Enter Message..."
                    value={nodeText}
                    disabled={isDisable}
                    onChange={handleLabelInputChange}
                  />
                  
                  <label className="node-label">PDF</label>
                  {uploadPDF.map((fileObj, index) => (
                    <div key={fileObj.id} className="input-group mb-2">
                      <Input
                        innerRef={pdfInputRefs.current[index]}
                        id={`examplePDFFile${index}`}
                        accept=".pdf"
                        disabled={isDisable}
                        name="file"
                        type="file"
                        className="form-control"
                        onChange={handlePDFFileChangeNew(index)}
                      />
                      <Button
                        className="btn"
                        onClick={handleRemovePDFFileInput(index)}
                        color="danger"
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    </div>
                  ))}
                  <div>
                    <button
                      className="add-button upload-button mb-2 mr-2"
                      onClick={handleUploadPDFNew}
                      disabled={isDisable}
                    >
                      Upload PDF
                    </button>
                    <button
                      className="add-button"
                      onClick={handleAddPDFFileInput}
                      disabled={isDisable}
                    >
                      +
                    </button>
                  </div>
                  <label className="node-label">Image</label>
                  {uploadImages.map((fileObj, index) => (
                    <div key={fileObj.id} className="input-group mb-2">
                      <Input
                        innerRef={imageInputRefs.current[index]}
                        id={`exampleFile${fileObj.id}`}
                        accept="image/*"
                        name="file"
                        type="file"
                        disabled={isDisable}
                        className="form-control"
                        onChange={handleFileChangeNew(index)}
                      />
                      {/* Delete button next to each input */}
                      <Button
                        className="btn"
                        onClick={handleRemoveFileInput(index)}
                        color="danger"
                        disabled={isDisable}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>

                    </div>
                  ))}

                  <div>
                    <button
                      className="add-button upload-button mb-2 mr-2"
                      onClick={handleUploadImageNew}
                      disabled={isDisable}
                    >
                      Upload Image
                    </button>
                    <button className="add-button" disabled={isDisable} onClick={handleAddFileInput}>
                      +
                    </button>
                  </div>

                  <label className="node-label">Video</label>
                  {uploadVideo.map((fileObj, index) => (
                    <div key={fileObj.id} className="input-group mb-2">
                      <Input
                        innerRef={videoInputRefs.current[index]}
                        id={`exampleFile${fileObj.id}`}
                        accept="video/*"
                        disabled={isDisable}
                        name="file"
                        type="file"
                        className="form-control"
                        onChange={handleVideoChangeNew(index)}
                      />
                      {/* Delete button next to each input */}
                      <Button
                        className="btn"
                        disabled={isDisable}
                        onClick={handleRemoveVideoInput(index)}
                        color="danger"
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>

                    </div>
                  ))}

                  <div>
                    <button
                      className="add-button upload-button mb-2 mr-2"
                      onClick={handleUploadVideoNew}
                      disabled={isDisable}
                    >
                      Upload Video
                    </button>
                    <button
                      className="add-button"
                      onClick={handleAddVideoInput}
                      disabled={isDisable}
                    >
                      +
                    </button>
                  </div>

                  <label className="node-label">URL Button</label>
                  <span style={{textAlign: "right", marginLeft: "87%",  fontSize: "11px", color: 'red'}} >{buttonUrl.title ? buttonUrl.title.length.toString().padStart(2,"0") : '00'}/20</span>
                  <div className="input-group mb-3">
                    <Input
                      id="button_title"
                      name="button_title"
                      placeholder="Enter Title"
                      type="text"
                      max={20}
                      disabled={isDisable}
                      className="form-control"
                      value={buttonUrl.title}
                      onChange={(e) => handleTitleChange(e)}
                    />
                  </div>
                  <span style={{textAlign: "right", marginLeft: "78%", right:0,  fontSize: "11px", color: 'red'}} >{buttonUrl.url ? buttonUrl.url.length.toString().padStart(4, '0'): '0000' }/2000</span>
                  <div className="input-group mb-3">
                    <Input
                      id="button_url"
                      name="button_url"
                      placeholder="Enter URL"
                      type="text"
                      className="form-control"
                      value={buttonUrl.url}
                      disabled={isDisable}
                      onChange={(e) => handleUrlChange(e)}
                    />
                  </div>
                  <span style={{textAlign: "right", marginLeft: "78%", right:0,  fontSize: "11px", color: 'red'}} >{buttonUrl.message ? buttonUrl.message.length.toString().padStart(4, '0') : '0000'}/1024</span>
                  <div className="input-group mb-3">
                    <Input
                      id="button_message"
                      name="button_message"
                      type="text"
                      placeholder="Enter Message"
                      className="form-control"
                      value={buttonUrl.message}
                      disabled={isDisable}
                      onChange={(e) => handleMessageChange(e)}
                    />
                  </div>
                </>
              )}
              {selectedNode.type === "conditionnode" && (
                <>
                  <label className="node-label">Label:</label>
                  <span style={{textAlign: "right", marginLeft: "87%", right:0,  fontSize: "11px", color: 'red'}} >{nodeLabel ? nodeLabel.length.toString().padStart(2, '0'): '00'}/20</span>
                  <Input
                    type="text"
                    className="custom-input"
                    value={nodeLabel}
                    disabled={isDisable}
                    onChange={(e) => handleInputChange(e.target.value)}
                  />
                  <label className="node-label">Text:</label>
                  <span style={{textAlign: "right", marginLeft: "78%", right:0,  fontSize: "11px", color: 'red'}} >{nodeText ? nodeText.length.toString().padStart(4, '0'): '0000'}/1024</span>
                  <Input
                    type="textarea"
                    className="custom-input"
                    value={nodeText}
                    disabled={isDisable}
                    onChange={handleLabelInputChange}
                  />
                  <label className="node-label">Select Option:</label>
                  <select
                    className="custom-input"
                    value={nodeType}
                    disabled={isDisable}
                    onChange={handleTypeInputChange}
                  >
                    <option value="">Select Option</option>
                    <option value="sbtn">Submit Button</option>
                    <option value="rbtn">Radio Button</option>
                  </select>
                </>
              )}

              <label className="node-label">Add Button:</label>
              {conditionValues.length > 0 &&
                conditionValues.map((item) => (
                  <>
                    <span style={{textAlign: "right", marginLeft: "78%", right:0,  fontSize: "11px", color: 'red'}} >{item.value.length.toString().padStart(2, '0')}/20</span>
                  <InputGroup key={item.id} className="add-group">
                    <Input
                      type="text"
                      className="custom-input"
                      value={item.value}
                      disabled={isDisable}
                      onChange={(e) =>
                        handleAddValueInputChange(e.target.value, item.id)
                      }
                    />
                    <Button
                      className="delete-btn"
                      onClick={() => removeConditionValue(item.id)}
                      disabled={isDisable}
                      color="danger"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </InputGroup>
                  </>
                ))}
              {/* {selectedNode.type === "conditionnode" && (
                <>
                  <label className="node-label">Add Values:</label>
                  {conditionValues.length > 0 &&
                    conditionValues.map((item) => (
                      <InputGroup key={item.id} className="add-group">
                        <Input
                          type="text"
                          className="custom-input"
                          disabled={isDisable}
                          value={item.value}
                          onChange={(e) =>
                            handleAddValueInputChange(e.target.value, item.id)
                          }
                        />
                        <Button
                          className="delete-btn"
                          onClick={() => removeConditionValue(item.id)}
                          color="danger"
                          disabled={isDisable}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                      </InputGroup>
                    ))}
                </>
              )} */}
              {selectedNode.type === "conditionnode" && (
                <button className="add-button" onClick={addConditionValue}>
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              )}
              {selectedNode.type === "textnode" && (
                <button
                  className="add-button"
                  onClick={addConditionValue}

                  style={{
                    backgroundColor: isButtonDisabled ? "#ccc" : "#007bff",
                    color: isButtonDisabled ? "#666" : "#fff",
                    cursor: isButtonDisabled ? "not-allowed" : "pointer",
                  }}
                  disabled={isButtonDisabled}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              )}

            </>
          ) : null}
          <button
            className="go-back-btn"
            onClick={() => setSelectedElements([])}
            disabled={isDisable}
          >
            Go Back
          </button>
        </div>
      ) : (
        <>
          <h3 className="update-label">Nodes Panel</h3>
          <div
            className="sidebar-btn"
            onDragStart={handleDragStart}
            draggable={!isNodeDisabled}
            style={{
              cursor: isNodeDisabled ? "not-allowed" : "grab",
              opacity: isNodeDisabled ? 0.5 : 1,
            }}
          >
            Start
          </div>
          <div
            className="sidebar-btn"
            onDragStart={(event) => onDragStart(event, "textnode")}
            draggable
          >
            Message
          </div>
          <div
            className="sidebar-btn"
            onDragStart={(event) => onDragStart(event, "conditionnode")}
            draggable
          >
            Condition
          </div>
          <div
            className="sidebar-btn"
            onDragStart={(event) => onDragStart(event, "agentnode")}
            draggable
          >
            Speak to Agent
          </div>
          <div
            className="sidebar-btn"
            onDragStart={(event) => onDragStart(event, "endnode")}
            draggable
          >
            End
          </div>
        </>
      )}
    </aside>
  );
}