import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLocalStorageUserData } from '../utils/session';
import { USER_ROLE } from '../constants/General';
import { AdminRoutes, RestaurantRoutes } from '../constants/Routes';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Login from '../pages/Login';
import Event from "../pages/Events";
import Organizations from "../pages/Organizations";
import Register from '../pages/Register';
import AdminDashboard from '../pages/Admin/pages/Main';
import RestaurantDashboard from '../pages/Restaurant/pages/Main';
import ResetPassword from '../pages/ResetPassword';
import ForgotPassword from '../pages/ForgotPassword';
import EmailVerification from '../pages/EmailVerification';
import ImagePreview from '../components/ImagePreview';
import Feedback from '../pages/Feedback';
import PaymentStatus from '../pages/PaymentStatus';
import Tickets from '../pages/Tickets';
import PaymentPage from '../pages/PaymentPage';
import Checkout from '../pages/Checkout';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsAndConditions from '../pages/TermsAndConditions';
import QRCodeScanner from '../pages/QRCodeScanner';

function Router() {
  const { isAdminUser, imagePreview, imageLink, fileType } = useSelector(state => state.user)
  let isAdmin = (isAdminUser || getLocalStorageUserData()?.role === USER_ROLE.SUPER_ADMIN)

  const getRoutes = (routes) => {
    return routes.map((prop, index) => {
      if (prop.layout === "/admin") {
        if (prop?.subMenu?.length) {
          return prop.subMenu?.map(subProp => {
            return (
              <Route key={index} path={prop.layout + subProp.layout + subProp.path} exact element={subProp.component} />
            )
          })
        } else {
          return (
            <Route key={index} path={prop.layout + prop.path} exact element={prop.component} />
          );
        }
      } else {
        return null;
      }
    });
  };

  return (
    <BrowserRouter>
      <Routes >
        <Route path="/" exact element={<Navigate to="/login" />} />
        <Route path="/register" exact element={<PublicRoute><Register /></PublicRoute>} />
        <Route path="/login" exact element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="/event/:name/:id" exact element={<PublicRoute><Event /></PublicRoute>} />
        <Route path="/organization/:name/:id" exact element={<PublicRoute><Organizations /></PublicRoute>} />
        <Route path="/forgotPassword" exact element={<PublicRoute><ForgotPassword /></PublicRoute>} />
        <Route path="/resetPassword" exact element={<PublicRoute><ResetPassword /></PublicRoute>} />
        <Route path="/emailVerification" exact element={<PublicRoute><EmailVerification /></PublicRoute>} />
        <Route path="/feedback" exact element={<Feedback />} />
        <Route path="/ticket/:name/:id" exact element={<Tickets />} />
        <Route path="/eventqr" exact element={<QRCodeScanner />} />
        <Route path="/success" exact element={<PaymentPage />} />
        <Route path="/cancel" exact element={<PaymentPage />} />
        <Route path="/paymentStatus" exact element={<PaymentStatus />} />
        <Route path="/checkout" exact element={<Checkout />} />
        <Route path="/termsAndConditions" exact element={<TermsAndConditions />} />
        <Route path="/privacyPolicy" exact element={<PrivacyPolicy />} />
        <Route path="/admin" exact element={<PrivateRoute>{isAdmin ? <AdminDashboard /> : <RestaurantDashboard />}</PrivateRoute>} >
          {getRoutes(isAdmin ? AdminRoutes : RestaurantRoutes)}
          <Route path="/admin" exact element={<Navigate to="/admin/dashboard" />} />
        </Route>
      </Routes >
      {imagePreview && <ImagePreview url={imageLink} fileType={fileType} />}
    </BrowserRouter >
  );
}

export default Router;
