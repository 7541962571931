import { PAYOUT_API, PAYOUT_EXPORT_API, EVENT_API } from "../../../../constants/Configs"
import { API_REQUEST } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { payoutsListDTO } from "../dtos/payouts"

export const getPayouts = ({ id }) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = { eventId: id }

  API(API_REQUEST.get, PAYOUT_API, { params })
    .then(res => {
      dispatch(Actions.RestaurantAdminPayouts.SetTables, payoutsListDTO(res.data.data.data))
      dispatch(Actions.RestaurantAdminPayouts.SetTotalCount, res.data.data.data.count)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const exportPayout = () => {
  return new Promise((resolve, reject) => {
    dispatch(Actions.RestaurantAdmin.SetLoading, true)

    API(API_REQUEST.get, `${PAYOUT_EXPORT_API}/payouts`)
      .then(res => {
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
        resolve(res)
      })
      .catch(err => {
        handleError(err)
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
        reject(err)
      })
  })
}

export const addOrganization = (data) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, `${EVENT_API}/organization`, data)
    .then((res) => {
      handleSuccess(res)
      getOrganization()
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const updateOrganization = (data) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const { id } = data
  API(API_REQUEST.put, `${EVENT_API}/organization/${id}`, data)
    .then((res) => {
      handleSuccess(res)
      getOrganization()
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const getOrganization = () => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const restaurant = JSON.parse(localStorage.getItem("userData"));
  const userRestaurantId = restaurant.restaurantId;
  API(API_REQUEST.get, `${EVENT_API}/organization/${userRestaurantId}`)
    .then(res => {
      console.log(res.data.data)
      dispatch(Actions.RestaurantAdminPayouts.SetOrganization, res.data.data)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const deletePayout = (id, search, activeSort, sortOrder, page, handleCloseDeleteModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, `${PAYOUT_API}/${id}/remove`)
    .then(res => {
      handleSuccess(res)
      handleCloseDeleteModal()
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}