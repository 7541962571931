import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

const EventOverview = ( { eventTitle, setEventTitle, eventSummary, setEventSummary } ) => {
  const maxSummaryLength = 140;
  const [buttonText, setButtonText] = useState('⚡ Generate campaign content through AI');

  return (
    <div className="border p-4 rounded">
      <h5 className="mb-3">Event Overview</h5>

      {/* Event Title */}
      <FormGroup>
        <Label for="eventTitle" className="fw-bold">Event title</Label>
        <p className="text-muted">Be clear and descriptive with a title that tells people what your event is about.</p>
        <Input
          id="eventTitle"
          type="text"
          placeholder="Event title*"
          value={eventTitle}
          onChange={(e) => setEventTitle(e.target.value)}
        />
      </FormGroup>

      {/* Summary */}
      <FormGroup>
        <Label for="summary" className="fw-bold">Summary</Label>
        <p className="text-muted">
          Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max) <a href="#">See examples</a>
        </p>
        <Input
          id="summary"
          type="textarea"
          placeholder="Summary*"
          maxLength={maxSummaryLength}
          value={eventSummary}
          row={5}
          onChange={(e) => setEventSummary(e.target.value)}
        />
        <div className="text-end text-muted">{eventSummary?.length} / {maxSummaryLength}</div>
      </FormGroup>

      {/* Suggest Summary Button */}
      <Button color="secondary" outline className="mt-2">
         {buttonText}
      </Button>
    </div>
  );
};

export default EventOverview;
