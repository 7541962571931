export function restaurantSettingsDTO(data) {
  if (data) {
    return {
      appId: data?.appId || '',
      appName: data?.appName || '',
      accessToken: data?.accessToken || '',
      callbackUrl: data?.callbackUrl,
      verifyToken: data?.verifyToken,
      phoneNumberId: data?.phoneNumberId || '',
      wabaId: data?.wabaId || '',
      fbCatalogId: data?.fbCatalogId || '',
      visitCount: data?.visitCount || '',
      serviceHours: data?.serviceHours || [],
      isAppConfigured: data?.isAppConfigured,
      isStripeAccount: data?.isStripeAccount,
      isStripeAccountSubmitted: data?.isStripeAccountSubmitted,
      allowLoyalty: data?.allowLoyalty,
      rewardCoinsPrice: data?.rewardCoinsPrice || '',
      googleFeedbackUrl: data?.googleFeedbackUrl || '',
      reservationUrl: data?.reservationUrl || '',
      rewardCoinsPerOrder: data?.rewardCoinsPerOrder || '',
      minimumOrderPrice: data?.minimumOrderPrice || '',
      minimumRewardedCoins: data?.minimumRewardedCoins || '',
      maxRedeemableCoins: data?.maxRedeemableCoins || '',
      isEmailNotificationEnabled: data?.isEmailNotificationEnabled,
      isPushNotificationEnabled: data?.isPushNotificationEnabled,
      reservationUrl: data?.reservationUrl,
      reservationThroughUrl: data?.reservationThroughUrl,
      reservationTimeInterval: data?.reservationTimeInterval || '',
      welcomePDF: data?.welcomePDF?.length ? data?.welcomePDF.map(file => ({
        fileUrl: file.fileUrl,
        fileName: file.fileName
      })) : [],
      superAdminSetting: data?.superAdminSetting ? {
        vat: data?.superAdminSetting?.vat,
        currency: data?.superAdminSetting?.currency,
        currencySign: data?.superAdminSetting?.currencySign
      } : null
    }
  }
}