import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import CreateEvent from "./../../components/EventCreateForm";

const EventCreate = () => {
  const [selectedPage, setSelectedPage] = useState("Build event page");

  const pages = {
    "Build event page": <BuildEventPage />,
    "Manage dates and times": <ManageDatesAndTimes />,
    "Add tickets": <AddTickets />,
    "Publish": <PublishPage />,
  };

  return (
    <Container fluid className="event-schedule">
      <Row style={{height: "80vh"}}>
        {/* Sidebar */}
        <Col md={3} className="sidebar p-3 bg-light d-flex flex-column sticky-top" style={{height: "100%"}}>
          <h4 className="text-center mb-3">Create Event</h4>
          <ListGroup>
            {Object.keys(pages).map((page) => (
              <ListGroupItem
                key={page}
                active={selectedPage === page}
                style={{ backgroundColor: selectedPage === page ? "#ed3c5d" : "" }}
                onClick={() => setSelectedPage(page)}
                action
              >
                {page}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>

        {/* Main Content */}
        <Col md={9} className="main-content overflow-auto" style={{height: "100%"}}>
          {pages[selectedPage]}
        </Col>
      </Row>
    </Container>
  );
};

/* Dummy Components for Different Pages */
const BuildEventPage = () => (
  <div>
    <CreateEvent />
  </div>
);

const ManageDatesAndTimes = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const dates = Array.from({ length: 31 }, (_, i) => i + 1);
  const timeslots = ["8:00am", "12:30pm", "4:00pm", "7:00pm"];

  return (
    <div>
      <h3>Manage Dates and Times</h3>
      <p>Start by adding the dates and time slots for your recurring event.</p>
      <Card className="calendar-card">
        <CardBody>
          <CardTitle>March 2025</CardTitle>
          <div className="calendar-grid">
            {dates.map((date) => (
              <Button
                key={date}
                color={selectedDate === date ? "primary" : "light"}
                onClick={() => setSelectedDate(date)}
                className="m-1"
              >
                {date}
              </Button>
            ))}
          </div>
        </CardBody>
      </Card>
      <Button color="primary" className="mt-3">+ Add a date</Button>
      <div className="timeslots mt-3">
        {dates.map((date) => (
          <Card key={date} className="timeslot-card mb-2">
            <CardBody>
              <CardTitle>
                <strong>
                  {["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][date % 7]}
                </strong>
                <span> March {date}</span>
              </CardTitle>
              <CardText>
                {timeslots.map((slot, index) => (
                  <Button key={index} color="light" className="mr-2">
                    {slot}
                  </Button>
                ))}
              </CardText>
              <small>No tickets</small>
            </CardBody>
          </Card>
        ))}
      </div>
    </div>
  );
};

const AddTickets = () => (
  <div>
    <h3>Add Tickets</h3>
    <p>Set ticket prices, quantities, and other options for your event.</p>
  </div>
);

const PublishPage = () => (
  <div>
    <h3>Publish Event</h3>
    <p>Review all event details and publish it when ready.</p>
  </div>
);

export default EventCreate;