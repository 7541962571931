import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import API from "../../services/api";
import { API_REQUEST } from "../../constants/General";
import { EVENT_API } from "../../constants/Configs";
import moment from "moment-timezone";
import { Buffer } from "buffer"; // Import buffer for better compatibility
import { PDFDownloadLink } from "@react-pdf/renderer";
import TicketPDF from "./TicketPDF";
import TicketDeatils from "./TicketDeatils";

const TicketView = () => {
  const [order, setOrder] = useState(null); // Use null to check loading
  const [qrCodeData, setQRCodeData] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null);
  const ticketRef = useRef(null);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const parts = new URL(window.location.href).pathname.split("/");
        const organizationName = decodeURIComponent(parts[2]);
        const _c = parts[3];
        // const _c = textToBase64(parts[3]);

        setOrganization(organizationName);

        const response = await API(API_REQUEST.post, `${EVENT_API}/order/public`, { eoptc: _c });

        console.log(response.data.data); // Update state
        if (response?.data?.data) {
          setOrder(response.data.data); // Update state
          const qrCodes = response.data.data.orderTickets.map((ticket) => ({
            ticketUniqueId: ticket.ticketUniqueId,
            ticketQuantity: ticket.ticketQuantity,
            ticketPrice: response.data.data.grossSales / response.data.data.orderTickets.length, // Assuming equal price split
            eventName: response.data.data.event.eventTitle, // Ticket/Event Name
          }));
          console.log(qrCodes);
          setQRCodeData(qrCodes); // Example QR Code
        } else {
          throw new Error("Invalid event data received");
        }
      } catch (err) {
        setError(err.message || "Something went wrong");
      } finally {
        setLoading(false); // Stop loading after fetching data
      }
    };

    fetchEventData();
  }, []);

  function textToBase64(text) {
    return Buffer.from(text, "utf-8").toString("base64");
  }

  const EventDetails = (eventData) => {
    if (!eventData) return null;
    const userTimeZone = moment.tz.guess();
    const formattedStart = moment.tz(`${eventData.eventDate} ${eventData.eventStartTime}`, "YYYY-MM-DD HH:mm", userTimeZone).format("MMMM D, YYYY, h:mm A");
    const formattedEnd = moment.tz(`${eventData.eventDate} ${eventData.eventEndTime}`, "YYYY-MM-DD HH:mm", userTimeZone).format("h:mm A");

    return formattedStart !== "Invalid date" ? `${formattedStart} | ${formattedEnd}` : "";
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center my-4">
        <Spinner color="primary" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="d-flex justify-content-center my-4">
        <p className="text-danger">{error}</p>
      </Container>
    );
  }

  return (
    <Container className="d-flex justify-content-center my-4 overflow-auto">
      <Card style={{ maxWidth: "900px", width: "100%", borderRadius: "10px", padding: "20px", height: "100%" }} ref={ticketRef}>
        <div ref={ticketRef}>
          <Row>
            {/* Left Side - Event Details */}
            <Col md={12} className="text-center">
              {/* <img src={order?.event?.eventMedia || "placeholder.jpg"} alt="Event" className="img-fluid rounded" /> */}
              <h3 className="mt-3 fw-bold">{order?.event?.eventTitle}</h3>
              <p>{EventDetails(order?.event)}</p>
              <p className="text-gray-700 font-semibold">{organization}</p>
              <PDFDownloadLink
                document={<TicketPDF order={order} qrCodeData={qrCodeData} organization={organization} />}
                fileName="ticket.pdf"
              >
                {({ loading }) => (loading ? "Loading PDF..." : "Download Ticket")}
              </PDFDownloadLink>
              {/* <Button color="dark" outline block className="mt-2">Cancel Order</Button> */}
              {/* <p className="text-primary mt-2" style={{ cursor: "pointer" }}>Contact the organizer</p>
              <p className="text-muted">Free Order 1183924233 on Feb 26, 2025</p>
              <p className="text-danger" style={{ cursor: "pointer" }}>Report this event</p> */}
            </Col>

            {/* Right Side - Contact Info & QR Code */}
            <Col md={12}>
              <Card className="p-3 border-0">
                <CardBody>
                  <h3 className="fw-bold">Contact Information</h3>
                  <p><strong>First Name:</strong> {order?.firstName || "N/A"}</p>
                  <p><strong>Last Name:</strong> {order?.lastName || "N/A"}</p>
                  <p><strong>Email:</strong> {order?.email || "N/A"}</p>
                  <p><strong>Delivery Method:</strong> eTicket</p>
                  <div className="d-flex justify-content-center mt-4 flex flex-column">

                  { qrCodeData.map
                    ((qrCodes, index) => (
                      <TicketDeatils value={qrCodes} order={order} EventDetails={EventDetails} organization={organization} index={index} size={180} />
                    ))  
                  }
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <h4 className="text-danger fw-bold"> Show QR code to enter in Event. </h4>
                  </div>
                </CardBody>
              </Card>
              {/* QR Code */}

            </Col>
          </Row>
        </div>
      </Card>
    </Container>
  );
};

export default TicketView;
