import React, { useEffect, useState } from "react";
// import { Html5QrcodeScanner, Html5Qrcode } from "html5-qrcode";
import API from "../../services/api";
import { API_REQUEST } from "../../constants/General";
import { EVENT_API } from "../../constants/Configs";
import { Container, Row, Col, Button, Alert, Input, Label, Card, CardBody, CardTitle } from "reactstrap";

const QRCodeScanner = () => {
  const [scannedData, setScannedData] = useState(null);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [attending, setAttending] = useState(0);
  const [error, setError] = useState(null);
  const [noEntry, setNoEntry] = useState(0);
  const [scanner, setScanner] = useState(null);

  const startScanner = () => {
    // const newScanner = new Html5QrcodeScanner("qr-reader", {
    //   fps: 10,
    //   qrbox: 250,
    //   aspectRatio: 1.0,
    //   disableFlip: false,
    // }, true);

    // newScanner.render(
    //   (decodedText) => {
    //     setScannedData(decodedText);
    //     newScanner.clear();
    //     fetchTicketDetails(decodedText);
    //   },
    //   (errorMessage) => {
    //     setError("No QR code detected. Please ensure the QR code is clear and well-lit.");
    //   }
    // );
    // setScanner(newScanner);
  };

  const fetchTicketDetails = async (data) => {
    try {
      const response = await API(API_REQUEST.get, `${EVENT_API}/verify/ticket/${data}`);
      setTicketDetails(response);
      setAttending(response.attended || 0);
    } catch (err) {
      setError("Invalid Ticket or API Error");
    }
  };

  const handleAttendanceUpdate = async () => {
    try {
      await API(API_REQUEST.post, `${EVENT_API}/update/ticket/${scannedData}`, { attended: attending });
      alert("Attendance updated successfully");
    } catch (err) {
      setError("Error updating attendance");
    }
  };

  const handleReset = () => {
    setScannedData(null);
    setTicketDetails(null);
    setAttending(0);
    setNoEntry(0);
    setError(null);
    if (scanner) scanner.clear();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    // const qrScanner = new Html5Qrcode("qr-reader");
    // try {
    //   const result = await qrScanner.scanFile(file, false);
    //   setScannedData(result);
    //   fetchTicketDetails(result);
    // } catch (err) {
    //   setError("Failed to read QR Code from image.");
    // }
  };

  return (
    <Container className="text-center mt-4">
      <Card className="shadow-lg p-4 bg-light">
        <CardTitle tag="h2" className="mb-4 text-primary">QR Code Scanner</CardTitle>
        <Button color="primary" className="mb-3" onClick={startScanner}>Scan QR Code</Button>
        <Input type="file" accept="image/*" className="mb-3" onChange={handleFileUpload} />
        <div id="qr-reader" className="border p-3 rounded bg-white"></div>
        {scannedData && (
          <Alert color="success" className="mt-3">Scanned Data: {scannedData}</Alert>
        )}
        {error && <Alert color="danger" className="mt-3">{error}</Alert>}
        {ticketDetails && (
          <CardBody className="mt-4 border rounded p-3 bg-white">
            <Alert color={ticketDetails.valid ? "success" : "danger"}>
              Ticket Status: {ticketDetails.valid ? "Valid ✅" : "Invalid ❌"}
            </Alert>
            <p className="font-weight-bold">Total Persons: {ticketDetails.totalPersons}</p>
            <p className="font-weight-bold">Already Attended: {ticketDetails.attended}</p>
            <Row className="align-items-center mt-3">
              <Col sm={6}>
                <Label for="attend" className="font-weight-bold">Attending Now:</Label>
                <Input 
                  type="number" 
                  id="attend" 
                  min="0" 
                  max={ticketDetails.totalPersons - ticketDetails.attended} 
                  value={attending} 
                  onChange={(e) => setAttending(Number(e.target.value))} 
                />
              </Col>
              <Col sm={6}>
                <Button color="primary" className="mt-3 w-100" onClick={handleAttendanceUpdate}>Update Attendance</Button>
              </Col>
            </Row>
            <Row className="align-items-center mt-3">
              <Col sm={6}>
                <Label for="noEntry" className="font-weight-bold">No Entry:</Label>
                <Input 
                  type="number" 
                  id="noEntry" 
                  min="0" 
                  value={noEntry} 
                  onChange={(e) => setNoEntry(Number(e.target.value))} 
                />
              </Col>
              <Col sm={6}>
                <Button color="danger" className="mt-3 w-100">Submit No Entry</Button>
              </Col>
            </Row>
          </CardBody>
        )}
        <Button color="secondary" className="mt-3 w-100" onClick={handleReset}>Reset</Button>
      </Card>
    </Container>
  );
};

export default QRCodeScanner;