import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

const EventForm = ({ eventLocation, setEventLocation, eventType, setEventType, eventStartDate, setEventStartDate, eventStartTime, setEventStartTime, eventEndTime, setEventEndTime, isOnline, setIsOnline }) => {

  return (
    <Card className="p-3">
      <CardTitle tag="h5">Date and Location</CardTitle>
      {/* Event Type */}
      <FormGroup>
        <Label>Type of Event</Label>
        <div className="d-flex">
          <Button
            color={eventType === "single" ? "primary" : "light"}
            onClick={() => setEventType("single")}
          >
            Single Event
          </Button>
          <Button
            color={eventType === "recurring" ? "primary" : "light"}
            onClick={() => setEventType("recurring")}
            className="ms-2"
          >
            Recurring Event
          </Button>
        </div>
      </FormGroup>

      {/* Date & Time for Single Event */}
      {eventType === "single" && (
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>Date</Label>
              <Input type="date" value={eventStartDate} onChange={(e) => setEventStartDate(e.target.value)} />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Start Time</Label>
              <Input type="time" value={eventStartTime} onChange={(e) => setEventStartTime(e.target.value)} />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>End Time</Label>
              <Input type="time" value={eventEndTime} onChange={(e) => setEventEndTime(e.target.value)} />
            </FormGroup>
          </Col>
        </Row>
      )}

      {/* Location Type */}
      <FormGroup>
        <Label>Location</Label>
        <div className="d-flex">
          <Button
            color={isOnline === "no" ? "primary" : "light"}
            onClick={() => setIsOnline("no")}
          >
            Venue
          </Button>
          <Button
            color={isOnline === "yes" ? "primary" : "light"}
            onClick={() => setIsOnline("yes")}
            className="ms-2"
          >
            Online Event
          </Button>
          <Button
            color={isOnline === "tba" ? "primary" : "light"}
            onClick={() => setIsOnline("tba")}
            className="ms-2"
          >
            To Be Announced
          </Button>
        </div>
      </FormGroup>

      {/* Location Input */}
      {isOnline === "no" && (
        <FormGroup>
          <Label>Location</Label>
          <Input type="text" value={eventLocation} onChange={(e) => setEventLocation(e.target.value)} placeholder="Enter location" />
        </FormGroup>
      )}

      {/* Google Map Placeholder */}
      {isOnline === "no" && (
        <div style={{ width: "100%", height: "300px", backgroundColor: "#e3e3e3", textAlign: "center", lineHeight: "200px" }}>
          <iframe
            title="Event Location"
            width="100%"
            height="300px"
            style={{ border: 0 }}
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAOVYRIgupAurZup5y1PRh8Ismb1A3lLao&q=${encodeURIComponent(
              eventLocation || "London, UK"
            )}`}
            allowFullScreen
          ></iframe>
        </div>
      )}

      {/* Reserved Seating */}
      <FormGroup>
        <Label>Reserved Seating</Label>
        <div style={{ fontSize: "small", color: "gray" }}>
          Use your venue map to set price tiers for each section and choose whether attendees can pick their seat.
        </div>
      </FormGroup>
    </Card>
  );
};

export default EventForm;
