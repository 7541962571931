// Reducers
import user from './reducers/index';
import restaurantAdmin from './reducers/restaurantAdmin/index';
import restaurantAdminTables from './reducers/restaurantAdmin/tables';
import restaurantAdminCategories from './reducers/restaurantAdmin/categories';
import restaurantAdminCustomers from './reducers/restaurantAdmin/customers';
import restaurantAdminTags from './reducers/restaurantAdmin/tags';
import restaurantAdminProducts from './reducers/restaurantAdmin/products';
import restaurantAdminOffers from './reducers/restaurantAdmin/offers';
import restaurantAdminCoupons from './reducers/restaurantAdmin/coupons';
import restaurantAdminMarketings from './reducers/restaurantAdmin/marketings';
import restaurantAdminOrders from './reducers/restaurantAdmin/orders';
import restaurantAdminReports from './reducers/restaurantAdmin/reports';
import restaurantAdminReservations from './reducers/restaurantAdmin/reservations';
import restaurantAdminEvents from './reducers/restaurantAdmin/events';
import restaurantAdminTickets from './reducers/restaurantAdmin/tickets';
import restaurantAdminPayouts from './reducers/restaurantAdmin/payouts';
import restaurantAdminTransactions from './reducers/restaurantAdmin/transactions';
import restaurantAdminFeedbacks from './reducers/restaurantAdmin/feedbacks';
import restaurantAdminTemplates from './reducers/restaurantAdmin/templates';
import restaurantAdminTemplatesAnalytics from './reducers/restaurantAdmin/templateAnalytics';
import restaurantAdminNotifications from './reducers/restaurantAdmin/notifications'
import superAdmin from './reducers/superAdmin/index';
import superAdminRestaurants from './reducers/superAdmin/restaurants';
import superAdminSubscriptions from './reducers/superAdmin/subscriptions';
import superAdminPayments from './reducers/superAdmin/payments';
import superAdminCMS from './reducers/superAdmin/cms'

// Utility Packages
import { combineReducers } from 'redux';

/* *********************** */
/* ***** Imports End ***** */
/* *********************** */

export default combineReducers({
  user,
  restaurantAdmin,
  restaurantAdminTables,
  restaurantAdminCategories,
  restaurantAdminCustomers,
  restaurantAdminTags,
  restaurantAdminProducts,
  restaurantAdminOffers,
  restaurantAdminCoupons,
  restaurantAdminMarketings,
  restaurantAdminOrders,
  restaurantAdminReports,
  restaurantAdminReservations,
  restaurantAdminEvents,
  restaurantAdminTickets,
  restaurantAdminPayouts,
  restaurantAdminTransactions,
  restaurantAdminFeedbacks,
  restaurantAdminTemplates,
  restaurantAdminTemplatesAnalytics,
  restaurantAdminNotifications,
  superAdmin,
  superAdminRestaurants,
  superAdminSubscriptions,
  superAdminPayments,
  superAdminCMS  
});
