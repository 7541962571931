import React from "react";
import { Handle, Position } from "reactflow";
import { v4 as uuidv4 } from 'uuid';
import chatbotImage from "./chatbot.png"; // Adjust the path relative to the file
import { alignPropType } from "react-bootstrap/esm/types";

const getId = () => uuidv4();

// Custom node
function AgentNode({ id, data, selected, onDelete }) {
    return (
        <div
            className={`agent-box ${selected ? "custom-border" : ""}`}
        >
            <button
                onClick={() => onDelete(id)}
                className="delete-node"
                title="Delete Node"
            >
                ×
            </button>
            <div className="d-flex flex-column">
                <div className="agent-node web-theme-color">
                    Speak to an agent
                </div>
                <div style={{alignItems: "center"}} >
                    <img src={chatbotImage} height={100} style={{margin:"1rem"}} alt="Chatbot" />
                </div>
            </div>

            <Handle
                id={getId()}
                type="target"
                position={Position.Left}
                className="custom-handle"
            />
        </div>
    );
}

export default AgentNode;
