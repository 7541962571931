import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import { campaignValidationSchema } from '../../../../constants/Schemas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../../components/InputField';
import { Actions } from '../../../../redux/actions';
import { dispatch } from '../../../../utils/store';
import { API_REQUEST, multiSelectDropdownStyle } from '../../../../constants/General';
import { TEMPLATES_API } from '../../../../constants/Configs';
import { templatesListDTO } from '../../utils/dtos/templates';
import { removeEmptyKeysFromObject, trimObjectValues, uploadImage } from '../../../../services/middleware';
import { handleError } from '../../../../utils/toast';
import { CAMPAIGN_FILE_TYPE, ImageLinks, ImageType, selectDropdownStyle } from '../../../../constants/General';
import { useSelector } from 'react-redux';
import { getCustomers } from '../../utils/middleware/marketings';
import "./campaign.css"
import moment from 'moment-timezone';
import API from "../../../../services/api"
import {GET_AICONTENT} from "../../../../constants/Configs"
import MobileViewCampaign from '../MobileViewCampaign';
export default function CampaignModal(props) {
  // Props
  
  const { open, campaign, handleCloseModal, handleSuccess } = props

  // Store
  const { tags, customers } = useSelector(state => state.restaurantAdminMarketings)
  const [buttonText, setButtonText] = useState('Generate campaign content through AI');
  //State
  const [templates, setTemplates] = useState([])
  // Use useCallback to memoize the debounced function
  const debouncedGetCustomers = useCallback(
    debounce((inputValue) => {
      if (inputValue) {
        getCustomers(inputValue);
      }
    }, 500), // Adjust the debounce delay as needed
    []
  );

  const handleInputChange = (inputValue) => {
    debouncedGetCustomers(inputValue);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      formik.setFieldValue('occuranceDay', [...formik.values.occuranceDay, name]);
    } else {
      let days = formik.values.occuranceDay;
      if(typeof formik.values.occuranceDay === 'string') {
        days = days.split(',')
      }
      formik.setFieldValue('occuranceDay', days.filter(day => day !== name));
    }
  };

  function sanitizeString(str) {
    return str.replace(/['"]/g, "");
  }

  const handleAIContentCall = (bodyText) =>{
    const payload = {
      "content": buttonText == 'Generate campaign content through AI' ? bodyText : 'Re Generate below content ' + bodyText,
    }
    dispatch(Actions.RestaurantAdmin.SetLoading, true)
    API(API_REQUEST.post, GET_AICONTENT,payload)
    .then(res => {
      if(res.status == 200){
        if(res.data.code == 200){
          formik.setFieldValue("message", sanitizeString(res.data.data));
          setButtonText('Re-Generate campaign content through AI');
        }else{
          handleError('Something went wrong.');
        }
      }else{
        handleError('Something went wrong.');
      }  
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
      .catch(err => {
        handleError(err)
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
  }

  const getTemplates = (fileType = 'text') => {
    dispatch(Actions.RestaurantAdmin.SetLoading, true)
    const params = {
      page: 1, 'size': 1000, isApproved: true
    }
    if(!fileType) fileType = 'text';     
    params.templateType = fileType && fileType.includes('image') ? "image" : fileType.includes("pdf") ? "pdf" : fileType.includes("video") ? "video" : "text";
    
    formik.setFieldValue("draftTemplateId", null)
    
    API(API_REQUEST.get, TEMPLATES_API, { params })
      .then(res => {
        const rowData = templatesListDTO(res?.data?.data?.rows)
        const templates = rowData?.map(template => ({ value: template.id, label: template.name }))
        setTemplates(templates)
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
      .catch(err => {
        handleError(err)
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
  }

  useEffect(() => {
    if(campaign){
      getTemplates(campaign?.mediaType)
    }else{
      getTemplates('text')
    }
    }, [])

  //week days selection
  const weekDaysList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const convertToUTC = (timeStr, flag) => {
    const timeFormat = "HH:mm";
    const localTzStr = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let utcTime;
    if(flag){
      const localTime = moment.tz(timeStr, timeFormat, 'UTC');
      utcTime = localTime.clone().tz(localTzStr);
    }else{
      const localTime = moment.tz(timeStr, timeFormat, localTzStr);
      utcTime = localTime.clone().tz('UTC');
    }
    return utcTime.format(timeFormat);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: campaign?.name || '',
      message: campaign?.message || '',
      allCustomers: campaign?.customers?.length === customers?.length && campaign?.customers?.every(campaignCustomer =>
        customers.some(customer => customer.id === campaignCustomer.id)
      ),
      customers: !!campaign?.customers?.length ? campaign?.customers.map(customer => ({ label: customer?.name || '', value: customer?.id || '' })) : [],
      tags: !!campaign?.tags?.length ? campaign?.tags.map(tag => ({ label: tag?.tagName || '', value: tag?.id || '' })) : [],
      media: campaign?.media || '',
      occuranceStartDate: campaign?.occuranceStartDate && campaign?.occuranceStartDate !== "0000-00-00" ? campaign?.occurance === 'repeat' ? campaign?.occuranceStartDate : '' : '',
      occuranceEndDate: campaign?.occuranceEndDate && campaign?.occuranceEndDate !== "0000-00-00" ? campaign?.occurance === 'repeat' ? campaign?.occuranceEndDate : '' : '',
      occuranceDay: typeof campaign?.occuranceDay === 'string' ? campaign?.occuranceDay.split(',') : [],
      occuranceDate: campaign?.occuranceDate && campaign?.occuranceDate !== "0000-00-00" ? campaign?.occurance === 'donotrepeat' ? campaign?.occuranceDate : '' : '',
      occuranceTime: campaign?.occuranceTime ? convertToUTC(campaign?.occuranceTime, true) : '',
      isScheduled: campaign?.isScheduled || false,
      occurance: campaign?.occurance || '',
      draftTemplateId: campaign?.draftTemplateId != null ? templates.find(template => template.value == campaign?.draftTemplateId) : null,
    },
    validationSchema: campaignValidationSchema,
    
    onSubmit: async (values) => {
      trimObjectValues(values, 'media')
      values.message = values.message ? values.message.replace(/\n/g, " ") : '';
      const filterData = removeEmptyKeysFromObject(values)
      if (filterData.media && !(typeof filterData.media === 'string' && ImageLinks.includes(filterData.media?.split(":")[0]))) {
        dispatch(Actions.RestaurantAdmin.SetLoading, true)
        const fileType = filterData?.media?.type?.split('/')[0]        
        const logoResponse = await uploadImage(filterData.media, fileType === 'pdf' ? ImageType.CAMPAIGN_PDF : fileType === 'video' ? ImageType.CAMPAIGN_VIDEO : ImageType.CAMPAIGN_IMAGE).catch(err => {
          dispatch(Actions.RestaurantAdmin.SetLoading, false)
          handleError(err)
        })
        filterData.media = logoResponse.fileLink;
        console.log(filterData,"typeeeeeeeeeeeeeeeeeeeeeeee")
        filterData.mediaType = fileType === 'image' ? 'image' : fileType === 'video' ? 'video' : 'pdf';
      } else if (filterData.media && typeof filterData.media === 'string' && ImageLinks.includes(filterData.media?.split(":")[0])) {
        const filename = filterData.media.substring(filterData.media.lastIndexOf('/') + 1); // Get filename from URL
        const extension = filename.split('.').pop().toLowerCase(); // Get extension
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
        const videoExtensions = ['mp4', 'mov', 'avi', 'mkv'];        
        filterData.mediaType = imageExtensions.includes(extension) ? 'image' : videoExtensions.includes(extension) ? 'video' : 'pdf';        
      }
      filterData.tags = filterData?.tags?.length ? filterData.tags.map(tag => tag.value) : []
      filterData.customers = filterData?.customers?.length ? filterData.customers.map(customer => customer.value) : []
      if (!!formik.values.allCustomers) {
        filterData.customers = customers
        delete filterData.allCustomers
        await getCustomers().then(res => {
          filterData.customers = res?.length ? res.map(customer => customer.value) : []
        })
      }
      if(values.isScheduled && formik.values.occurance === 'donotrepeat'){
        filterData.occuranceStartDate = '';
        filterData.occuranceEndDate =  '';
        filterData.occuranceDay =  [];
        delete filterData.occuranceDay;
      }else{
        filterData.occuranceDate = "";
      }
      filterData.occuranceTime = convertToUTC(values.occuranceTime);
      filterData.occuranceDay = weekDaysList.filter(day => values.occuranceDay.includes(day));
      filterData.draftTemplateId = values.draftTemplateId ? values.draftTemplateId.value : null;
      filterData.isScheduled = values.isScheduled;
      console.log(filterData);
      handleSuccess(filterData);
    }
  });
  return (
    <Modal
      className="modal-dialog-centered edit-modal"
      isOpen={open}
      toggle={handleCloseModal}
      size='lg'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Campaign
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0 edit-template-body" >
        <Card className="border-0 edit-card">
          <CardBody>
            {console.log(formik.values)}
            <Form role="form">
              <Row>
                <Col md={6}>
                  <InputField required placeholder="Name" fieldName="name" formik={formik} />
                </Col>
                <Col md={6}>
                  <InputField placeholder="Media" fieldName="media" inputType="file" action={getTemplates} accept={CAMPAIGN_FILE_TYPE} formik={formik} />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Label for="exampleSelect" className='inputfield'>
                    Tags
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select isMulti className="custom-select" options={tags} value={formik.values?.tags} isSearchable={false} styles={selectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("tags", selectedOption);
                      }}
                    />
                    {formik.errors.tags && <span className="error-message input-group-alternative">{formik.errors.tags}</span>}
                  </section>
                </Col>
                <Col md={2} className='flex-center all-customers-checkbox'>
                  <FormGroup check inline className="custom-checkbox">
                    <Input type="checkbox" name='allCustomers' checked={formik.values.allCustomers} onChange={formik.handleChange} />
                  </FormGroup>
                  <Label className="inputfield mb-0">All Customers</Label>
                </Col>
                <Col md={6}>
                  <Label className='inputfield'>
                    Customers
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select isMulti isDisabled={formik.values.allCustomers} value={formik.values.customers} onInputChange={handleInputChange} styles={selectDropdownStyle}
                      onChange={selectedOption => formik.setFieldValue("customers", selectedOption)}
                      options={customers} className="custom-select" />
                    {!!formik.errors?.customers?.length && Array.isArray(formik.errors?.customers) ? <span className="error-message input-group-alternative">{formik.errors.customers.map((customer, index) => (`customer ${index + 1} ${customer?.value}`)).join(",")}</span>
                      : formik.errors.customers && <span className="error-message input-group-alternative">{formik.errors.customers}</span>}
                  </section>
                </Col>
              </Row>
              <Row>
                <Col md={6} className='d-flex align-items-center my-4'>
                  <FormGroup check inline className="custom-checkbox">
                    <Input type="checkbox" name='isScheduled' checked={formik.values.isScheduled} onChange={formik.handleChange} />
                  </FormGroup>
                  <Label className="inputfield mb-0">Scheduled</Label>
                </Col>
                {formik.values.isScheduled && (
                  <Col md={6}>
                    <Label className="inputfield mb-0">Occurance<span className="required-field"></span></Label>
                    <div className="d-flex aling-items-center justify-content-evenly">
                      <FormGroup check className='mr-4'>
                        <Input
                          name="occurance"
                          type="radio"
                          value="donotrepeat"
                          checked={formik.values.occurance === 'donotrepeat'}
                          onChange={formik.handleChange}
                        />
                        {' '}
                        <Label check>
                          Do Not Repeat
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          name="occurance"
                          type="radio"
                          value="repeat"
                          checked={formik.values.occurance === 'repeat'}
                          onChange={formik.handleChange}
                        />
                        {' '}
                        <Label check>
                          Repeat
                        </Label>
                      </FormGroup>
                    </div>
                  </Col>
                )}
              </Row>
              {formik.values.isScheduled && formik.values.occurance === 'repeat' && (
                <Row>
                  <Col>
                    <Label className='inputfield'>
                      Occurance Days<span className="required-field"></span>
                    </Label>
                    <div className='d-flex flex-wrap justify-content-between my-4'>
                      {weekDaysList.map(day => (
                        <div key={day} className="d-flex align-items-center justify-content-center ml-2">
                          <FormGroup check inline className="custom-checkbox pl-2">
                            <Input type="checkbox" name={day} checked={formik.values.occuranceDay.includes(day)} onChange={handleCheckboxChange} />
                          </FormGroup>
                          <Label className="inputfield mb-0">{day}</Label>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              )}
              {formik.values.isScheduled && formik.values.occurance === 'donotrepeat' && (
                <Row>
                  <Col md={5}>
                    <InputField placeholder="Scheduled Date" required fieldName="occuranceDate" inputType="date" formik={formik} />
                  </Col>
                  <Col md={2}>
                    <InputField placeholder="Time" required fieldName="occuranceTime" inputType="time" formik={formik} />
                  </Col>
                </Row>
              )}
              {formik.values.isScheduled && formik.values.occurance === 'repeat' && (
                <Row>
                  <Col md={5}>
                    <InputField placeholder="From" required fieldName="occuranceStartDate" inputType="date" formik={formik} />
                  </Col>
                  <Col md={5}>
                    <InputField placeholder="TO" required fieldName="occuranceEndDate" inputType="date" formik={formik} />
                  </Col>
                  <Col md={2}>
                    <InputField placeholder="Time" required fieldName="occuranceTime" inputType="time" formik={formik} />
                  </Col>
                </Row>
              )}
              {formik.values.isScheduled && (
                <Row>
                  <Col >
                    <Label className='inputfield'>
                      Template<span className="required-field"></span>
                    </Label>
                    <section className="mb-3 input-group-alternative">
                      <Select className="custom-select" value={formik.values.draftTemplateId} options={templates} isSearchable={false} styles={multiSelectDropdownStyle}
                        onChange={selectedOption => {
                          formik.setFieldValue("draftTemplateId", selectedOption);
                        }}
                      />
                    </section>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <InputField 
                      placeholder="Message" 
                      fieldName="message" 
                      inputType="textarea" 
                      rows={5} 
                      formik={formik} 
                      showCharactorCount 
                      maxLimit={1024} 
                    />
                </Col>
              </Row>
              <div className="modal-footer p-0 m-0" style={{right: "10px", bottom: "10px"}}>
                  <Button
                    color="outline-primary"
                    data-dismiss="modal"
                    type="button"
                    style={{padding: "5px", fontSize: "11px"}}
                    onClick={() => handleAIContentCall(formik.values.message)}
                  >
                    {buttonText}
                  </Button>
                  {/* <Button
                    color="outline-primary"
                    data-dismiss="modal"
                    type="button"
                    style={{padding: "5px", fontSize: "11px"}}
                    onClick={() => handleAIContentCall('re generate below content ' + formik.values.message)}
                  >
                    Re-Generate campaign content through AI
                  </Button> */}
                </div>
            </Form>
          </CardBody>
        </Card>
        <div className="edit-view">
          <MobileViewCampaign template={formik.values} formik = {formik}/>
        </div>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal>
  )
}
