import React, { useEffect, useState } from "react";
import { Document, Page, Text, View, Image, StyleSheet, Link } from "@react-pdf/renderer";
import QRCode from "qrcode";
import moment from "moment-timezone";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },
  section: {
    marginBottom: 10,
    padding: 10,
    border: "1px solid #ddd",
    borderRadius: 5,
  },
  header: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
ticketContainer: {
  flexDirection: "row", // Aligns details and QR code in a row
  justifyContent: "space-between", // Pushes QR code to the right
  alignItems: "center",
  borderBottomWidth: 1, // Only bottom border
  borderBottomColor: "#ed3c5d", // Border color
  paddingBottom: 5, // Spacing between text and border
  marginBottom: 10, // Space between tickets
},
  ticketDetails: {
    flex: 1, // Allows text to take available space
  },
  qrCodeContainer: {
    width: 150, // Set width to match QR code size
  },  
  qrCode: {
    width: 150,
    height: 150,
    alignSelf: "right",
    marginTop: 10,
  },
});

const TicketPDF = ({ order, organization }) => {
  const [qrCodes, setQrCodes] = useState([]);

  useEffect(() => {
    const generateQRCodes = async () => {
      if (order?.orderTickets) {
        const qrPromises = order.orderTickets.map((ticket) =>
          QRCode.toDataURL(ticket.ticketUniqueId, { width: 150 })
        );
        const qrResults = await Promise.all(qrPromises);
        setQrCodes(qrResults);
      }
    };

    generateQRCodes();
  }, [order?.tickets]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Event Details */}
        <View style={styles.section}>
          <Text style={styles.header}>{order?.event?.eventTitle}</Text>
          <Text style={styles.text}>Date: {order?.event?.eventDate}</Text>
          <Text style={styles.text}>Location: {order?.event?.eventLocation}</Text>
          <Text style={styles.text}>Organizer: {organization || "N/A"}</Text>
        </View>

        {/* Contact Information */}
        <View style={styles.section}>
          <Text style={styles.header}>Contact Information</Text>
          <Text style={styles.text}>First Name: {order?.firstName || "N/A"}</Text>
          <Text style={styles.text}>Last Name: {order?.lastName || "N/A"}</Text>
          <Text style={styles.text}>Email: {order?.email || "N/A"}</Text>
          <Text style={styles.text}>Delivery Method: eTicket</Text>
        </View>

        {/* Tickets Section */}
          <View style={styles.section}>
            <Text style={styles.header}>Ticket Details</Text>
            {order?.orderTickets?.map((ticket, index) => (
                <View style={styles.ticketContainer} key={index}>
                  {/* Ticket Details */}
                  <View style={styles.ticketDetails}>
                    <Text style={styles.text}>Ticket Name: {ticket?.name || "NA"}</Text>
                    <Text style={styles.text}>Quantity: {ticket?.ticketQuantity}</Text>
                    <Text style={styles.text}>Price: ₹{ticket?.ticketPrice || "NA"}</Text>
                    <Text style={styles.text}>Total Paid: ₹{order.netRevenue}</Text>
                  </View>

                  {/* QR Code */}
                  {qrCodes[index] && (
                      <Image style={styles.qrCode} source={{ uri: qrCodes[index] }} />
                  )}
                </View>
              
            ))}
          </View>

        {/* Footer */}
        <View style={styles.section}>
          <Text style={{ textAlign: "center", marginTop: 10, color: "red", fontWeight: "bold" }}>
            Show QR code to enter the event.
          </Text>
          <Text style={{ textAlign: "center", marginTop: 5 }}>
            Event managed by <Link src="https://wepos.ai">Wepos.ai</Link>
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default TicketPDF;
