import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import { Input, Label, List } from 'reactstrap';
import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import { v4 as uuidv4 } from 'uuid';

const getId = () => uuidv4();

function TextNode({ id, data, selected, onDelete }) {
  const nodeRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 20, height: 20 });
  const updateNodeInternals = useUpdateNodeInternals();
  const [preview, setPreview] = useState([]);
  const [pdfPreview, setPdfPreview] = useState([]);
  const [videoPreview, setVideoPreview] = useState([]);

  const positionHandle = (index, total, nodeHeight) => {
    const spacing = 15;
    const handleHeight = spacing * (total - 1) / 2;
    const adjustedTop = Math.max(0, (nodeHeight - handleHeight * 2) / 2) + spacing * index;

    return adjustedTop;
  };

  useEffect(() => {
    if(data.file.length > 0){      
      setPreview(data.file)      
    }
    if(data.pdf.length > 0){
      setPdfPreview(data.pdf)
    }    
    if(data.video.length > 0){
      setVideoPreview(data.video)
    }    
  }, [data]);

  useLayoutEffect(() => {
    if (nodeRef.current) {      
      const valueCount = data.value.length;
      const handleSpacing = 10;
      const itemHeight = 30;
      const padding = 20;

      const newHeight = Math.max(
        100,
        valueCount * itemHeight + padding + handleSpacing * (valueCount - (valueCount -1))
      );

      setDimensions({
        width: nodeRef.current.offsetWidth,
        height: newHeight
      });

      // Force React Flow to update node internals after setting dimensions
      updateNodeInternals(id);
    }
  }, [data.value.length, id, updateNodeInternals]);

  return (
    <div
      ref={nodeRef}
      className={`condition-box ${selected ? "custom-border" : ""}`}
    >
        <button
          onClick={() => onDelete(id)}
          className="delete-node"
          title="Delete Node"
        >
          ×
        </button>
      <div className="d-flex flex-column">
      <div className="text-node-header">
        Messgage
        </div>
        {data && data.text && <div className="px-3 py-2"><textarea
          placeholder="Add Text"
          value={data.text}
          className="add-label"
          disabled
          rows={5}
          style={{ padding: '0.25rem', height: '100%', width: '100%' }}
        /></div>}
        <List className="condition-list">
          {data && data.value.length > 0 && data.value.map((e) => {
            if (e.value) {
              return (
                <li key={e.id}>
                  <Input
                    value={e.value}
                    name="chat-fun"
                    type='submit'
                  />

                </li>
              );
            }
            return null;
          })}
        </List>

        <div className="mb-2">
          {data && data.file && data.file.length > 0 && data.file.map((e) => (
              <div className="node-preview image-node" key={e.fileName}>
                  {e.fileName}
              </div>
          ))}
          {data.file.length == 0 && preview.length > 0 && preview.map((e) => (
              <div className="node-preview image-node" key={e.fileName}>
                  {e.fileName}
              </div>
          ))}
        </div>

        <div className="mb-2">
          {data && data.video && data.video.length > 0 && data.video.map((e) => (
              <div className="node-preview image-node" key={e.fileName}>
                  {e.fileName}
              </div>
          ))}
          {data.video.length == 0 && videoPreview.length > 0 && videoPreview.map((e) => (
              <div className="node-preview image-node" key={e.fileName}>
                  {e.fileName}
              </div>
          ))}
        </div>
        

        {data && data.pdf && data.pdf.length > 0 && data.pdf.map((e) => (
            <div className="node-preview pdf-node" key={e.fileName}>
                {e.fileName}
            </div>
        ))}
        {data.pdf.length === 0 && pdfPreview.length > 0 && pdfPreview.map((e) => (
            <div className="node-preview image-node" key={e.fileName}>
                {e.fileName}
            </div>
        ))}
     
      </div>

      <Handle
        id={getId()}
        type="target"
        position={Position.Left}
        className="custom-handle"
      />
      {data && data.value.length > 0 && data.value.map((e, i) => {        
        if (e.value) {
          return (
            <Handle
              id={e.id}
              type="source"
              position={Position.Right}
              className="custom-handle"
              style={{ top: positionHandle(i, data.value.length, dimensions.height) }}
              key={e.id}
            />
          );  
        }
        return null;
      })}


    </div>
  );
}

export default TextNode;