export function payoutsListDTO(data) {
    let events = [];

    if (data?.length) {
        events = data.map((event) => ({
            id: event?.id || '',
            amount: event?.amount || '',
            chargesAndCredits: event?.chargesAndCredits || '',
            createdAt: event?.createdAt || '',
            deletedAt: event?.deletedAt || '',
            eventName: event?.eventName || '',
            eventId: event?.eventId || '',
            expectedArrivalDate: event?.expectedArrivalDate || '',
            initiatedDate: event?.initiatedDate || '',
            netSales: event?.netSales || '',
            payoutDate: event?.payoutDate || '',
            paidOut: event?.paidOut || '',
            payoutMethod: event?.payoutMethod || '',
            payoutType: event?.payoutType || '',
            status: event?.status || '',
            updatedAt: event?.updatedAt || ''
        }))
    }

    return events;
}

export function eventsSelectListDTO(data) {
    let events = [];

    if (data?.length) {
        events = data.map((event) => ({
            value: event?.id || '',
            label: event?.eventTitle || '',
        }))
    }

    return events;
}