// Base URL
const REACT_APP_AUTH_URL = process.env.REACT_APP_AUTH_URL;
const REACT_APP_CATEGORY_URL = process.env.REACT_APP_CATEGORY_URL;
const REACT_APP_COUPON_URL = process.env.REACT_APP_COUPON_URL;
const REACT_APP_CUSTOMER_URL = process.env.REACT_APP_CUSTOMER_URL;
const REACT_APP_PRODUCTS_URL = process.env.REACT_APP_PRODUCTS_URL;
const REACT_APP_RESTAURANT_URL = process.env.REACT_APP_RESTAURANT_URL;
const REACT_APP_RESTAURANT_SETTING_URL = process.env.REACT_APP_RESTAURANT_SETTING_URL;
const REACT_APP_SUBSCRIPTION_URL = process.env.REACT_APP_SUBSCRIPTION_URL;
const REACT_APP_TABLES_URL = process.env.REACT_APP_TABLES_URL;
const REACT_APP_UPLOAD_FILES_URL = process.env.REACT_APP_UPLOAD_FILES_URL;
const REACT_APP_MARKETING_CONTENT_URL = process.env.REACT_APP_MARKETING_CONTENT_URL;
const REACT_APP_ORDER_URL = process.env.REACT_APP_ORDER_URL;
const REACT_APP_FEEDBACK_URL = process.env.REACT_APP_FEEDBACK_URL;
const REACT_APP_REPORTS_URL = process.env.REACT_APP_REPORTS_URL;
const REACT_APP_RESERVATIONS_URL = process.env.REACT_APP_RESERVATIONS_URL;
const REACT_APP_RESTAURANT_DASHBOARD_URL = process.env.REACT_APP_RESTAURANT_DASHBOARD_URL;
const REACT_APP_SUPER_ADMIN_DASHBOARD_URL = process.env.REACT_APP_SUPER_ADMIN_DASHBOARD_URL;
const REACT_APP_SUBSCRIPTION_PAYMENT_URL = process.env.REACT_APP_SUBSCRIPTION_PAYMENT_URL;
const REACT_APP_SUPER_ADMIN_SETTING_URL = process.env.REACT_APP_SUPER_ADMIN_SETTING_URL;
const REACT_APP_MESSAGES_API = process.env.REACT_APP_MESSAGES_API;
const REACT_APP_RESTAURANT_TAGS_API = process.env.REACT_APP_RESTAURANT_TAGS_API;
const REACT_APP_RESTAURANT_STRIPE_URL = process.env.REACT_APP_RESTAURANT_STRIPE_URL;
const REACT_APP_TEMPLATES_API = process.env.REACT_APP_TEMPLATES_API;
const REACT_APP_SUPER_ADMIN_LOGIN_API = process.env.REACT_APP_SUPER_ADMIN_LOGIN_API;
const REACT_APP_CHAT_AUTOMATION_NODE_ADD_API = process.env.REACT_APP_CHAT_AUTOMATION_NODE_ADD_API;
const REACT_APP_NOTIFICTAION_REGISTER = process.env.REACT_APP_NOTIFICTAION_REGISTER;
const REACT_APP_CMS_API = process.env.REACT_APP_CMS_API;
const REACT_APP_AICONTENT_API = process.env.REACT_APP_AICONTENT_API;

// ******  Admin ****** //
export const LOGIN_API = `${REACT_APP_AUTH_URL}/login`;
export const LOGIN_OTP_API = `${REACT_APP_AUTH_URL}/v2/login`;
export const OTP_API = `${REACT_APP_AUTH_URL}/v2/login/otp`;
export const RESEND_OTP_API = `${REACT_APP_AUTH_URL}/v2/resend/otp`;
export const FORGOT_PASSWORD_API = `${REACT_APP_AUTH_URL}/forgot-password`;
export const RESET_PASSWORD_API = `${REACT_APP_AUTH_URL}/reset-password`;
export const EMAIL_VERIFICATION_API = `${REACT_APP_AUTH_URL}/email-verification`;
export const TABLES_API = `${REACT_APP_TABLES_URL}/tables`;
export const CATEGORIES_API = `${REACT_APP_CATEGORY_URL}/categories`;
export const CUSTOMERS_API = `${REACT_APP_CUSTOMER_URL}/customers`;
export const CUSTOMERS_LIST_API = `${REACT_APP_CUSTOMER_URL}/customers-list`;
export const TAGS_API = `${REACT_APP_RESTAURANT_TAGS_API}/customer-group-tag`;
export const TAGS_LIST_API = `${REACT_APP_RESTAURANT_TAGS_API}/customer-group-tag-list`;
export const PRODUCTS_API = `${REACT_APP_PRODUCTS_URL}/products`;
export const CAMPAIGNS_API = `${REACT_APP_MARKETING_CONTENT_URL}/campaigns`;
export const ORDERS_API = `${REACT_APP_ORDER_URL}/orders`;
export const COUPONS_API = `${REACT_APP_COUPON_URL}/coupons`;
export const REPORTS_API = `${REACT_APP_REPORTS_URL}/reports`;
export const SUBSCRIPTION_PAYMENT_API = `${REACT_APP_SUBSCRIPTION_PAYMENT_URL}/subscriptions`;
export const RESERVATIONS_API = `${REACT_APP_RESERVATIONS_URL}/reservations`;
export const FEEDBACKS_API = `${REACT_APP_FEEDBACK_URL}/feedbacks`;
export const FEEDBACKS_POST_API = `${REACT_APP_ORDER_URL}/feedbacks`;
export const RESTAURANT_DASHBOARD_API = `${REACT_APP_RESTAURANT_DASHBOARD_URL}/restaurant/dashboard`;
export const RESTAURANT_SETTING_API = `${REACT_APP_RESTAURANT_SETTING_URL}/restaurants/settings`;
export const RESTAURANT_EMBEDDED_SETTING_API = `${REACT_APP_RESTAURANT_SETTING_URL}restaurants/embedded/settings`;
export const RESTAURANT_STRIPE_API = `${REACT_APP_RESTAURANT_STRIPE_URL}/restaurants`;
export const TEMPLATES_API = `${REACT_APP_TEMPLATES_API}/templates`;
export const SUPER_ADMIN_LOGIN_API = `${REACT_APP_SUPER_ADMIN_LOGIN_API}/super-admin-login`;
export const CHATAUTOMATION_NODE_API = `${REACT_APP_CHAT_AUTOMATION_NODE_ADD_API}/node`
export const CHATAUTOMATION_EDGE_API = `${REACT_APP_CHAT_AUTOMATION_NODE_ADD_API}/edge`
export const CHATAUTOMATION_GET_NODE_API = `${REACT_APP_CHAT_AUTOMATION_NODE_ADD_API}/node/restaurant`;
export const CHATAUTOMATION_GET_FLOW_NODE_API = `${REACT_APP_CHAT_AUTOMATION_NODE_ADD_API}/node/flow`;
export const CHATAUTOMATION_GET_FLOW_EDGE_API = `${REACT_APP_CHAT_AUTOMATION_NODE_ADD_API}/edge/flow`;
export const CHATAUTOMATION_GET_EDGE_API = `${REACT_APP_CHAT_AUTOMATION_NODE_ADD_API}/edge/restaurant`;
export const FLOWLIST_GET_API = `${REACT_APP_CHAT_AUTOMATION_NODE_ADD_API}/node/restaurant`;
export const FLOW_CREATE_API = `${REACT_APP_CHAT_AUTOMATION_NODE_ADD_API}/flow`;
export const CHAT_NOTIFICATION_REGISTER_API = `${REACT_APP_NOTIFICTAION_REGISTER}/register/device`
export const CHAT_NOTIFICATION_API = `${REACT_APP_NOTIFICTAION_REGISTER}/notification`
export const UPDATE_CHAT_NOTIFICATION_API = `${REACT_APP_NOTIFICTAION_REGISTER}/read/notifications`
// ******  Super Admin ****** //
export const RESTAURANT_API = `${REACT_APP_RESTAURANT_URL}/restaurants`;
export const SETTINGS_API = `${REACT_APP_SUPER_ADMIN_SETTING_URL}/admin/settings`;
export const SUPER_ADMIN_DASHBOARD_API = `${REACT_APP_SUPER_ADMIN_DASHBOARD_URL}/admin/dashboard`;
export const SUBSCRIPTIONS_API = `${REACT_APP_SUBSCRIPTION_URL}/subscriptions`;
export const MESSAGES_API = `${REACT_APP_MESSAGES_API}/admin/messages`;

// ******  Common ****** //
export const CHANGE_PASSWORD_API = `${REACT_APP_AUTH_URL}/change-password`;
export const UPLOAD_IMAGE_API = `${REACT_APP_UPLOAD_FILES_URL}/upload-image`;

// ******  CMS ****** //
export const GET_ARTICALS_API = `${REACT_APP_CMS_API}/articles`;
export const GET_CATEGORIES_API = `${REACT_APP_CMS_API}/categories`;
export const CREATE_ARTICALS = `${REACT_APP_CMS_API}/articles`;
// export const GET_ARTICALS_BY_ID_API = `${REACT_APP_CMS_API}/getArticalsById`;
export const UPDATE_ARTICALS = `${REACT_APP_CMS_API}/articles`;
export const DELETE_ARTICALS = `${REACT_APP_CMS_API}/articles`;

export const GET_AICONTENT = `${REACT_APP_AICONTENT_API}/campaign/content`;
//Events
export const EVENT_API = `${REACT_APP_CATEGORY_URL}/events`;
export const TICKET_API = `${REACT_APP_CATEGORY_URL}/tickets`;
export const PAYOUT_API = `${REACT_APP_CATEGORY_URL}/payouts`;
export const PAYOUT_EXPORT_API = `${REACT_APP_CATEGORY_URL}/export`;
export const EVENT_PAYMENT_API = `${REACT_APP_CATEGORY_URL}/create/payment/intent`;
export const EVENT_PAYMENT_REFUND_API = `${REACT_APP_CATEGORY_URL}/refund/payment/intent`;
export const EVENT_PAYMENT_VERIFY_API = `${REACT_APP_CATEGORY_URL}/verify/payment/status`;