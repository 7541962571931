import { toast } from "react-toastify";
import { MessageType, StatusCode } from "../../constants/General";
import { removeSessionData } from "../session";

export function showMessage(content, type) {
  const message = typeof (content) === 'string' ? content.replaceAll('"', '').replaceAll('\\', '') : content;

  switch (type) {
    case MessageType.Info:
      toast.info(message, { position: 'bottom-right', theme: 'colored' });
      break;

    case MessageType.Error:
      toast.error(message, { position: 'bottom-right', theme: 'colored' });
      break;

    case MessageType.Warn:
      toast.warn(message, { position: 'bottom-right', theme: 'colored' });
      break;

    case MessageType.Success:
      toast.success(message, { position: 'bottom-right', theme: 'colored' });
      break;

    default:
      toast(message, { position: 'bottom-right', theme: 'colored' });
      break;
  }
}

export function handleError(error, type) {
  const status = error?.response?.status;
  const message = error?.response?.data?.message ? JSON.stringify(error.response.data.message) : error?.message ? JSON.stringify(error?.message) : type ? error : null;
  
  if (status) {
    if (status === StatusCode.UnAuthorized) {
      if (message) {
        showMessage(message, MessageType.Error);
      } else {
        showMessage('Session expired.', MessageType.Error);
      }
      removeSessionData()
    } else if (message) {
      showMessage(message, MessageType.Error);
    } else {
      showMessage('Something went wrong.', MessageType.Error);
    }
  } else {
    showMessage('Something went wrong.', MessageType.Error);
  }
}

export function handleSuccess(response) {  
  if ((response?.status == 200 || response?.status == 201 || response?.status == 202) && response?.data?.message) {
    if (response?.data?.message) {
    }
    showMessage(JSON.stringify(response.data.message), MessageType.Success);      
  } else {
    showMessage(response?.data?.message, MessageType.Error);
  }
}