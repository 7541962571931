import moment from "moment";

export default function events(
  initialState = {
    events: [],
    tables: [],
    search: '',
    eventList: [],
    eventOrder: [],
    // startDate: moment().startOf('month').toISOString(),
    // endDate: moment().endOf('month').toISOString(),
    activeSort: '',
    sortOrder: '',
    totalCount: 0,
    page: 1
  },
  action
) {
  switch (action.type) {
    case 'SET_RESTAURANT_ADMIN_EVENTS':
      return {
        ...initialState,
        events: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_EVENT':
      return {
        ...initialState,
        eventList: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_EVENT_TABLES':
      return {
        ...initialState,
        events: action.payload,
      };
    
    case 'SET_RESTAURANT_ADMIN_EVENT_ORDER_TABLES':
      return {
        ...initialState,
        eventOrder: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_EVENTS_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_EVENTS_START_DATE':
      return {
        ...initialState,
        startDate: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_EVENTS_END_DATE':
      return {
        ...initialState,
        endDate: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_EVENTS_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_EVENTS_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_EVENT_TOTAL_COUNT':
      return {
        ...initialState,
        totalCount: action.payload,
      };
    
    case 'SET_RESTAURANT_ADMIN_EVENTS_PAGE':
      return {
        ...initialState,
        page: action.payload,
      };


    case 'RESTAURANT_ADMIN_EVENTS_RESET':
      return {
        ...initialState,
        events: [],
        tables: [],
        search: '',
        startDate: moment().startOf('month').toISOString(),
        endDate: moment().endOf('month').toISOString(),
        activeSort: '',
        sortOrder: '',
        totalCount: 0,
        page: 1
      };

    default:
      return initialState;
  }
}
