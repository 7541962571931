import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";

import { getEventOrders, refundPayment, verifyPayment } from "../../utils/middleware/events";
import { useSelector } from 'react-redux'

import {
  Users,
  DollarSign,
  Ticket,
  MessageCircle,
  RefreshCcw,
  XCircle,
  Edit2,
} from "lucide-react";
import moment from "moment-timezone";

function EventDashboardModal({ open, handleCloseModal, eventStats, event }) {
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [activeTab, setActiveTab] = useState("orders");
  const [selectedOrder, setSelectedOrder] = useState(null);

  const { eventOrder } = useSelector(state => state.restaurantAdminEvents);
  const { totalCount } = useSelector(state => state.restaurantAdminOrders);

  // Pagination States for Orders
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = totalCount.itemsPerPage;
  const totalOrderPages = totalCount.totalPages;

  // Get formatted date
  const getFormatedDate = (date) => {
    return moment(date).format("DD-MM-YYYY HH:mm:ss", "12");
  };

  // const getFormatedDate = (date) => {
  //   const systemTimezone = moment.tz.guess();

  //   // Convert UTC to local system time
  //   const localDate = moment.tz(date, systemTimezone).format("DD-MM-YYYY HH:mm:ss");

  //   // Format as per local system preferences
  //   const systemFormattedDate = new Intl.DateTimeFormat(undefined, {
  //     dateStyle: "full",
  //     timeStyle: "long",
  //     timeZone: systemTimezone
  //   }).format(new Date(date));

  //   return localDate;
  // };


  const handleRefund = (attendee) => {
    // Implement refund logic here
    refundPayment({ id: attendee.id, eventId: attendee.eventId })
    alert(`Processing refund for ${attendee.firstName} ${attendee.lastName}`);
  };

  const handleCancel = (attendee) => {
    // Implement cancellation logic here
    // getEventOrders({ id: event.id })
    alert(`Cancelling order for ${attendee.firstName} ${attendee.lastName}`);
  };

  const handleVerify = (attendee) => {
    // Implement cancellation logic here
    console.log(attendee);
    verifyPayment({ sessionId: attendee.sessionId })
    alert(`Cancelling order for ${attendee.firstName} ${attendee.lastName}`);
  };

  const handleModify = (attendee) => {
    setSelectedOrder(attendee);
    // Implement modification logic here
    // getEventOrders({ sessionId: event.sessionId })
    alert(`Modifying order for ${attendee.firstName} ${attendee.lastName}`);
  };

  
  // Function to handle page change
  const handlePageChange = (newPage) => {
    console.log(newPage);
    if (newPage >= 1 && newPage <= totalOrderPages) {
      setCurrentPage(newPage);
      getEventOrders({ id: event.id, page: newPage });
    }
  };
  useEffect(() => {
    if (event && event.id) {
      getEventOrders({ id: event.id, page: currentPage });
    }
  }, [selectedAttendees]);

  const getStatusBadge = (status) => {
    const colors = {
      "completed": "success",
      "refunded": "primary",
      "pending": "warning",
      "failed": "danger",
      "paid": "success",
    };
    return <Badge color={colors[status]}>{status}</Badge>;
  };

  const closeBtn = (
    <button className="close" onClick={handleCloseModal} type="button">
      &times;
    </button>
  );

  // Pagination Logic for Orders
  return (
    <Modal isOpen={open} toggle={handleCloseModal} fullscreen={true} size="xl" style={{ maxWidth: '1500px' }}>
      <ModalHeader toggle={handleCloseModal} close={closeBtn} >Event Dashboard</ModalHeader>
      <ModalBody>
        <Nav tabs className="mb-4">
          {/* <NavItem>
            <NavLink
              className={activeTab === "overview" ? "active" : ""}
              onClick={() => setActiveTab("overview")}
              style={{ cursor: "pointer" }}
            >
              Overview
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              className={activeTab === "orders" ? "active" : ""}
              onClick={() => setActiveTab("orders")}
              style={{ cursor: "pointer" }}
            >
              Order Management
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="overview">
            <Row className="mb-4">
              <Col md={3}>
                <Card className="bg-primary text-white">
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h6>Total Revenue</h6>
                        <h3>${eventStats.revenue}</h3>
                      </div>
                      <DollarSign size={24} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="bg-success text-white">
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h6>Tickets Sold</h6>
                        <h3>
                          {eventStats.soldTickets}/{eventStats.totalTickets}
                        </h3>
                      </div>
                      <Ticket size={24} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="bg-info text-white">
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h6>Attendance</h6>
                        <h3>
                          {eventStats.attendedCount}/{eventStats.soldTickets}
                        </h3>
                      </div>
                      <Users size={24} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <div style={{ height: "150px" }}>
                  {/* {  <Doughnut
                    data={chartData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                  /> } */}
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5>Attendees List</h5>
              {/* <Button
                color="success"
                className="d-flex align-items-center gap-2"
                onClick={handleSendThankYou}
              >
                <MessageCircle size={18} />
                Send Thank You
                {selectedAttendees.length > 0 &&
                  ` (${selectedAttendees.length})`}
              </Button> */}
            </div>

            <Table striped responsive>
              <thead>
                <tr>
                  {/* <th>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setSelectedAttendees(
                          e.target.checked
                            ? eventStats.attendees.map((a) => a.id)
                            : []
                        );
                      }}
                      checked={
                        selectedAttendees.length === eventStats.attendees.length
                      }
                    />
                  </th> */}
                  <th>No</th>
                  <th>Name</th>
                  <th>Attending Time</th>
                  <th>Phone Number</th>
                </tr>
              </thead>
              <tbody>
                {eventStats.attendees.map((attendee, index) => (
                  <tr key={attendee.id}>
                    {/* <td>
                      <input
                        type="checkbox"
                        checked={selectedAttendees.includes(attendee.id)}
                        onChange={(e) => {
                          setSelectedAttendees(
                            e.target.checked
                              ? [...selectedAttendees, attendee.id]
                              : selectedAttendees.filter(
                                  (id) => id !== attendee.id
                                )
                          );
                        }}
                      />
                    </td> */}
                    <td>{++index}</td>
                    <td>{attendee.name}</td>
                    <td>{attendee.attendingTime}</td>
                    <td>{attendee.phoneNumber}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TabPane>

          <TabPane tabId="orders">
            <h5 className="mb-4">Order Management</h5>
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Customer</th>
                  <th>Event Details</th>
                  <th>Contact Info</th>
                  <th>Tickets</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>

                {eventOrder && eventOrder.length > 0 && eventOrder.map((attendee) => (
                  <tr key={attendee.id}>
                    <td>
                      <small className="text-muted">#{attendee.id}</small>
                    </td>
                    <td>{`${attendee.firstName} ${attendee.lastName}`}</td>
                    <td>
                      <div>{attendee.events?.eventName || "Test Event"}</div>
                      <small className="text-muted">{getFormatedDate(attendee.orderDateTime)}</small>
                    </td>
                    <td>
                      <div>{attendee.phone}</div>
                      <small className="text-muted">{attendee.email}</small>
                    </td>
                    <td>{attendee.orderTickets.reduce((sum, ticket) => sum + ticket.ticketQuantity, 0)}</td>
                    <td>£{attendee.netRevenue}</td>
                    <td>{getStatusBadge(attendee.orderStatus && attendee.orderStatus.toLowerCase())}</td>
                    <td>
                      <div className="d-flex gap-2">
                        <Button
                          color="info"
                          size="sm"
                          id={`verify-${attendee.id}`}
                          onClick={() => handleVerify(attendee)}
                        >
                          <RefreshCcw size={14} />
                        </Button>
                        <UncontrolledTooltip target={`verify-${attendee.id}`}>
                          Verify Payment
                        </UncontrolledTooltip>

                        <Button
                          color="warning"
                          size="sm"
                          id={`refund-${attendee.id}`}
                          onClick={() => handleRefund(attendee)}
                        >
                          <RefreshCcw size={14} />
                        </Button>
                        <UncontrolledTooltip target={`refund-${attendee.id}`}>
                          Process Refund
                        </UncontrolledTooltip>

                        <Button
                          color="danger"
                          size="sm"
                          id={`cancel-${attendee.id}`}
                          onClick={() => handleCancel(attendee)}
                        >
                          <XCircle size={14} />
                        </Button>
                        <UncontrolledTooltip target={`cancel-${attendee.id}`}>
                          Cancel Order
                        </UncontrolledTooltip>

                        <Button
                          color="primary"
                          size="sm"
                          id={`modify-${attendee.id}`}
                          onClick={() => handleModify(attendee)}
                        >
                          <Edit2 size={14} />
                        </Button>
                        <UncontrolledTooltip target={`modify-${attendee.id}`}>
                          Modify Order
                        </UncontrolledTooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Pagination Controls for Orders */}
            <div className="d-flex justify-content-between">
              <Button
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </Button>
              <span>
                Page {currentPage} of {totalOrderPages}
              </span>
              <Button
                disabled={currentPage === totalOrderPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </Button>
            </div>
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
}

export default EventDashboardModal;
