import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, Media, Table, Row, Button, Badge } from "reactstrap";
import NoDataLabel from '../../../../components/NoDataLabel';
import { deleteCustomer, editCustomer, getCustomer, getCustomers, importCustomer } from '../../utils/middleware/customers';
import ConfirmationModal from '../../../../components/ConfirmationModal'
import CustomerModal from '../../components/CustomerModal';
import CustomPagination from '../../../../components/Pagination';
import { dispatch, dispatchNoPayload } from '../../../../utils/store';
import { Actions } from '../../../../redux/actions';
import { handleSortColumn, sortIcon } from '../../../../services/middleware';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchInput from '../../../../components/SearchInput';
import { RoutesActions } from '../../../../constants/General';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import CustomersTagsModal from '../../components/CustomersTagsModal';
import ImportCustomersModal from '../../components/ImportCustomersModal';
import moment from 'moment';
import DateRangeSelector from "../../components/DateRangeSelector/index.jsx";
import {calenderViewMode} from "../../../../constants/General/index.js";

export default function Customers() {
  // Store
  const { customers, search, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminCustomers)
  const { pageSize } = useSelector(state => state.user)

  // State
  const [showEditModal, setShowEditModal] = useState(false)
  const [showCustomersTagsModal, setShowCustomersTagsModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const storedStartDate = localStorage.getItem('startDate');
  const storedEndDate = localStorage.getItem('endDate');
  const [dateRangeSelector, setDateRangeSelector] = useState(false)
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toISOString());
  const [endDate, setEndDate] = useState(moment().toISOString());


  // Mount
  useEffect(() => {
    if(storedStartDate){
      getCustomers(search, activeSort, sortOrder, page, storedStartDate, storedEndDate)
      setStartDate(storedStartDate)
      setEndDate(storedEndDate)
      setTimeout(() => {
        localStorage.removeItem('startDate');
        localStorage.removeItem('endDate');
      }, 2000);
      
    }else{      
      getCustomers(search, activeSort, sortOrder, page, startDate, endDate)
    }
  }, [search, activeSort, sortOrder, page, pageSize,storedStartDate,storedEndDate, startDate, endDate]);


  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedCustomer(null)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
    setSelectedCustomer(null)
  }

  // Unmount
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminCustomers.Reset), [])

  return (
    <div>
      <div className="custom-container">
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 space-between-div table-header-div">
                <h3 className="mb-0">Customers List</h3>
                <div className="right-div-wrap">
                <Button color="primary" outline onClick={() => setDateRangeSelector(true)}>
                  {moment(startDate).format('DD/MM/YYYY')} - {moment(endDate).format('DD/MM/YYYY')}
                </Button>
                  <SearchInput action={RoutesActions.customers} />
                  <Button color='primary' onClick={() => setShowImportModal(true)}>
                    Import Customer
                  </Button>
                </div>
              </CardHeader>
              {customers.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                <thead>
                  <tr>
                    {/* <th scope='col' className='serial-number cursor-pointer' onClick={() => handleSortColumn('', '', '', 'customers')}>No.</th> */}
                    <th scope='col' className={`${activeSort === 'name' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('name', activeSort, sortOrder, 'customers')}>Name <FontAwesomeIcon icon={sortIcon(activeSort, 'name', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'rewardCoins' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('rewardCoins', activeSort, sortOrder, 'customers')}>Coins <FontAwesomeIcon icon={sortIcon(activeSort, 'rewardCoins', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'whatsappNumber' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('whatsappNumber', activeSort, sortOrder, 'customers')}>Whatsapp Number <FontAwesomeIcon icon={sortIcon(activeSort, 'whatsappNumber', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'isSubscribe' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('isSubscribe', activeSort, sortOrder, 'customers')}>Subscribed <FontAwesomeIcon icon={sortIcon(activeSort, 'isSubscribe', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'createdAt' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('createdAt', activeSort, sortOrder, 'customers')}>Created Date <FontAwesomeIcon icon={sortIcon(activeSort, 'createdAt', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'about' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('about', activeSort, sortOrder, 'customers')}>About <FontAwesomeIcon icon={sortIcon(activeSort, 'about', sortOrder)} /></th>
                    <th scope='col' className="text-right">Tags</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((customer, index) => {
                    return <tr key={index}>
                      {/* <td className='serial-number'>{index + 1 + (page - 1) * pageSize}</td> */}
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {customer.customer.name}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{customer.rewardCoins}</td>
                      <td>{customer.customer.whatsappNumber}</td>
                      <td> <Badge className={customer.isSubscribe ? "badge-success" : "badge-danger"} pill>
                        {customer.isSubscribe ? 'Yes' : 'No'}
                      </Badge></td>
                      <td >{customer.createdAt ? moment(customer.createdAt).format('DD-MM-YYYY') : ''}</td>
                      <td>{customer.customer.about}</td>
                      <td className="text-right">
                        <Button color="warning" className='action-icon-btn' title='Tags' onClick={async () => {
                          await getCustomer(customer?.customer?.id).then((res) => setSelectedCustomer(res))
                          setShowCustomersTagsModal(true)
                        }}>
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                      </td>
                    </tr>
                  })}
                </tbody>
              </Table> : <NoDataLabel />}
              <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminCustomers.SetPage, selected + 1)} />
            </Card>
          </div>
        </Row>
      </div>
      {dateRangeSelector && <DateRangeSelector
        open={dateRangeSelector}
        handleCloseModal={() => setDateRangeSelector(false)}
        startDate={startDate}
        endDate={endDate}
        viewMode={calenderViewMode.day}
        setStartDate={(date) => setStartDate(date)}
        setEndDate={(date) => setEndDate(date)}
      />}

      {showDeleteModal && <ConfirmationModal open={showDeleteModal} handleCloseModal={handleCloseDeleteModal} handleSuccess={() => deleteCustomer(selectedCustomer?.id, handleCloseDeleteModal)} />}
      {showEditModal && <CustomerModal open={showEditModal} customer={selectedCustomer} handleCloseModal={handleCloseEditModal} handleSuccess={(data) => editCustomer(selectedCustomer?.id, data, handleCloseEditModal)} />}
      {showCustomersTagsModal && <CustomersTagsModal open={showCustomersTagsModal} tags={selectedCustomer.tags} handleCloseModal={() => setShowCustomersTagsModal(false)} />}
      {showImportModal && <ImportCustomersModal open={showImportModal} handleCloseModal={() => setShowImportModal(false)} handleSuccess={(data) => importCustomer(data, setShowImportModal)} />}
    </div >
  )
}
