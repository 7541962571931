import moment from "moment";

export default function payouts(
  initialState = {
    payouts: [],
    tables: [],
    search: '',
    eventList: [],
    // startDate: moment().startOf('month').toISOString(),
    // endDate: moment().endOf('month').toISOString(),
    activeSort: '',
    sortOrder: '',
    totalCount: 0,
    page: 1
  },
  action
) {
  switch (action.type) {
    case 'SET_RESTAURANT_ADMIN_PAYOUT':
      return {
        ...initialState,
        payouts: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_PAYOUT':
      return {
        ...initialState,
        eventList: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_PAYOUT_TABLES':
      return {
        ...initialState,
        payouts: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_PAYOUT_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };
    
    case 'SET_RESTAURANT_ADMIN_ORGANIZATION':
    return {
      ...initialState,
      organization: action.payload,
    };

    case 'SET_RESTAURANT_ADMIN_PAYOUT_START_DATE':
      return {
        ...initialState,
        startDate: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_PAYOUT_END_DATE':
      return {
        ...initialState,
        endDate: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_PAYOUT_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_PAYOUT_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_PAYOUT_TOTAL_COUNT':
      return {
        ...initialState,
        totalCount: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_PAYOUT_PAGE':
      return {
        ...initialState,
        page: action.payload,
      };


    case 'RESTAURANT_ADMIN_PAYOUT_RESET':
      return {
        ...initialState,
        payouts: [],
        tables: [],
        search: '',
        startDate: moment().startOf('month').toISOString(),
        endDate: moment().endOf('month').toISOString(),
        activeSort: '',
        sortOrder: '',
        totalCount: 0,
        page: 1
      };

    default:
      return initialState;
  }
}
