import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import API from "./../../../../services/api";
import { API_REQUEST } from "./../../../../constants/General";
import { EVENT_API } from "./../../../../constants/Configs";
import { verifyTicket } from "../../utils/middleware/events";
import { Container, Row, Col, Card, CardBody, CardTitle, Input, Label, Button, Alert } from "reactstrap";

const TicketScannerForm = () => {
  const [scannedData, setScannedData] = useState(null);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [attendeeCount, setAttendeeCount] = useState(1);
  const [attendees, setAttendees] = useState([{ name: "", whatsapp: "", email: "" }]);
  const [error, setError] = useState(null);
  const [isValid, setIsValid] = useState("");

  const startScanner = (decodedText) => {
    if (decodedText) {
      setScannedData(decodedText);
      fetchTicketDetails(decodedText);
    }
  };

  const fetchTicketDetails = async (data) => {
    try {
      const response = await API(API_REQUEST.get, `${EVENT_API}/verify/ticket/${data}`);
      setTicketDetails(response?.data?.data);
      setIsValid(response?.data?.message);
    } catch (err) {
      setError("Invalid Ticket or API Error");
    }
  };

  const handleAttendeeChange = (index, field, value) => {
    const updatedAttendees = [...attendees];
    updatedAttendees[index][field] = value;
    setAttendees(updatedAttendees);
  };

  const handleAttendeeCountChange = (event) => {
    const count = Math.max(1, parseInt(event.target.value) || 1);
    setAttendeeCount(count);
    setAttendees(Array.from({ length: count }, () => ({ name: "", whatsapp: "", email: "" })));
  };

  const handleAttendanceUpdate = () => {
    try {
      const payload = {
        customers: attendees,
        restaurantId: ticketDetails?.restaurantId,
        eventId: ticketDetails?.eventId,
        eventOrderId: ticketDetails?.eventOrderId,
        ticketUniqueId: ticketDetails?.ticketUniqueId,
      };
      verifyTicket(payload);
    } catch (err) {
      setError("Error updating attendance");
    }
  };

  return (
    <Container fluid className="vh-100 p-4" style={{ position: "sticky", top: 0 }}>
      <Row className="h-100">
        <Col md={6} className="d-flex flex-column">
          <Card className="mb-3 shadow-lg">
            <CardBody>
              <CardTitle tag="h5">QR Code Scanner</CardTitle>
              <div style={{ width: "100%" }}>
                <QrReader delay={300} onError={setError} onResult={startScanner} facingMode="user" />
              </div>
              {ticketDetails && (
                <Alert color={isValid === "Success" ? "success" : "danger"} className="mt-3">
                  Ticket Status: {isValid === "Success" ? "Valid ✅" : "Invalid ❌"}
                </Alert>
              )}
            </CardBody>
          </Card>

          {ticketDetails && (
            <Card className="shadow-lg">
              <CardBody>
                <CardTitle tag="h5">Ticket Details</CardTitle>
                <p>Total Persons: {ticketDetails.ticketQuantity}</p>
                <p>Already Attended: {ticketDetails.ticketVerified}</p>
                <Label for="attendeeCount">Number of Attendees</Label>
                <Input
                  type="number"
                  id="attendeeCount"
                  value={attendeeCount}
                  onChange={handleAttendeeCountChange}
                  min="1"
                  max={ticketDetails.ticketQuantity - ticketDetails.ticketVerified}
                />
              </CardBody>
            </Card>
          )}
        </Col>

        <Col md={6} className="p-4">
          <Card className="shadow-lg h-100">
            <CardBody style={{ maxHeight: "800px", overflowY: "auto" }}>
              <CardTitle tag="h5">Attendee Details</CardTitle>
              {attendees.map((attendee, index) => (
                <div key={index} className="mb-3 p-3 border rounded bg-light">
                  <h6 className="fw-bold">Attendee {index + 1}</h6>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    value={attendee.name}
                    onChange={(e) => handleAttendeeChange(index, "name", e.target.value)}
                  />
                  <Label className="mt-2">WhatsApp Number</Label>
                  <Input
                    type="text"
                    value={attendee.whatsapp}
                    onChange={(e) => handleAttendeeChange(index, "whatsapp", e.target.value)}
                  />
                  <Label className="mt-2">Email</Label>
                  <Input
                    type="email"
                    value={attendee.email}
                    onChange={(e) => handleAttendeeChange(index, "email", e.target.value)}
                  />
                </div>
              ))}
              <Button color="success" className="mt-3 w-100" onClick={handleAttendanceUpdate}>
                Confirm Entry
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TicketScannerForm;